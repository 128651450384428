// src/utils/withAuth.js

import { auth } from '../firebase';

export function withAuth(fetchFunction) {
  return async function (...args) {
    const user = auth.currentUser;
    if (!user) {
      throw new Error('No authenticated user');
    }

    try {
      const token = await user.getIdToken();
      const response = await fetchFunction(...args, token);
      return response;
    } catch (error) {
      console.error('Error in authenticated request:', error);
      throw error;
    }
  };
}
