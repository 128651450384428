// src/pages/ChatbotPage/ChatbotIntroModal.js

import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, HelpCircle, Smile, ChevronDown, ChevronUp } from 'lucide-react';

const ChatbotIntroModal = ({ onClose, onSendSuggestedQuery }) => {
  const [isSuggestionsOpen, setIsSuggestionsOpen] = useState(false);

  const suggestedQueries = [
    'Can you create a skincare routine for me?',
    'Recommend a moisturizer for my skin.',
    'Which sunscreen is suitable for me?',
  ];

  return (
    <AnimatePresence>
      <motion.div
        className="fixed inset-0 bg-black bg-opacity-40 flex items-center justify-center z-50 p-4"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <motion.div
          className="relative bg-white p-6 sm:p-8 rounded-xl max-w-md w-full max-h-screen overflow-y-auto shadow-2xl"
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.8, opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          {/* Close Button */}
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 transition-colors"
            aria-label="Close"
          >
            <X size={24} />
          </button>

          {/* Header */}
          <div className="text-center mb-6">
            <Smile size={48} className="mx-auto text-primary mb-4" />
            <h2 className="text-2xl font-bold text-gray-800">
              Welcome to Your AI Skincare Assistant!
            </h2>
            <p className="text-gray-600 mt-2">
              Personalized skincare advice and product recommendations tailored just for you.
            </p>
          </div>

          {/* How It Works */}
          <div className="mb-6">
            <h3 className="text-lg font-semibold text-gray-800 mb-4">How It Works</h3>
            <div className="space-y-4">
              {[
                {
                  step: '1',
                  title: 'Ask Questions',
                  description: 'Type any skincare-related question or concern you have.',
                },
                {
                  step: '2',
                  title: 'Get Personalized Advice',
                  description: 'Receive tailored advice based on your skin profile and concerns.',
                },
                {
                  step: '3',
                  title: 'Discover Products',
                  description: 'Get curated product recommendations that match your needs.',
                },
              ].map((item, index) => (
                <div key={index} className="flex items-start space-x-3">
                  <div className="flex-shrink-0">
                    <div className="h-8 w-8 rounded-full bg-primary text-white flex items-center justify-center font-semibold">
                      {item.step}
                    </div>
                  </div>
                  <div>
                    <p className="text-gray-800 font-medium">{item.title}</p>
                    <p className="text-gray-600 text-sm">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Suggested Queries Section */}
          <div className="mb-6">
            <button
              onClick={() => setIsSuggestionsOpen(!isSuggestionsOpen)}
              className="flex items-center justify-between w-full px-4 py-3 bg-gray-50 hover:bg-gray-100 rounded-lg transition-colors text-gray-800 font-medium focus:outline-none"
              aria-expanded={isSuggestionsOpen}
              aria-controls="suggested-queries"
            >
              <span>Try Asking</span>
              {isSuggestionsOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
            </button>

            <AnimatePresence>
              {isSuggestionsOpen && (
                <motion.div
                  id="suggested-queries"
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ duration: 0.3 }}
                  className="mt-2 space-y-2"
                >
                  {suggestedQueries.map((query, index) => (
                    <button
                      key={index}
                      onClick={() => {
                        onSendSuggestedQuery(query);
                        onClose();
                      }}
                      className="w-full text-left px-4 py-3 bg-gray-50 hover:bg-gray-100 rounded-md transition-colors text-gray-800 shadow-sm"
                    >
                      {query}
                    </button>
                  ))}
                </motion.div>
              )}
            </AnimatePresence>
          </div>

          {/* Footer */}
          <div className="text-center">
            <p className="text-gray-600 mb-4 text-sm">
              Need to see this again? Click the{' '}
              <HelpCircle size={16} className="inline" /> icon anytime.
            </p>
            <button
              onClick={onClose}
              className="w-full sm:w-auto px-5 py-3 bg-primary text-white rounded-full font-medium hover:bg-primary-dark transition-colors"
            >
              Start Chatting
            </button>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default ChatbotIntroModal;
