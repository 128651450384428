// src/pages/AccountPage/FavoriteProducts.js

import React, { useState, useEffect } from 'react';
import { Trash2, ExternalLink, Star } from 'lucide-react';
import { motion } from 'framer-motion';
import { 
  getSavedProducts, 
  removeSavedProduct, 
  getProductDetails 
} from '../../services/api';
import { useAuth } from '../../contexts/AuthContext';
import { decodeProductId } from '../../utils/decodeProductId';

const FavoriteProducts = () => {
  const [favoriteProducts, setFavoriteProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [removingProductIds, setRemovingProductIds] = useState(new Set());
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState('all');
  const [sort, setSort] = useState('name');
  const { token } = useAuth();

  useEffect(() => {
    const fetchFavoriteProducts = async () => {
      try {
        const savedProductsData = await getSavedProducts(token);
        const savedProductIdsEncoded = savedProductsData.saved_products;

        if (savedProductIdsEncoded.length === 0) {
          setFavoriteProducts([]);
          setLoading(false);
          return;
        }

        const savedProductIds = savedProductIdsEncoded
          .map(id => decodeProductId(id))
          .filter(id => id !== null);

        if (savedProductIds.length === 0) {
          setFavoriteProducts([]);
          setLoading(false);
          return;
        }

        const productsDetailsData = await getProductDetails(savedProductIds, token);
        if (productsDetailsData.success) {
          console.log('Fetched Products:', productsDetailsData.products); // Debugging line
          setFavoriteProducts(productsDetailsData.products);
        } else {
          throw new Error(productsDetailsData.error || 'Failed to fetch product details');
        }
        setLoading(false);
      } catch (err) {
        console.error('Error fetching favorite products:', err);
        setError('Failed to load favorite products. Please try again later.');
        setLoading(false);
      }
    };

    if (token) {
      fetchFavoriteProducts();
    } else {
      setError('You must be logged in to view favorite products.');
      setLoading(false);
    }
  }, [token]);

  const handleRemove = async (productId) => {
    if (removingProductIds.has(productId)) return;

    setRemovingProductIds(prev => new Set(prev).add(productId));

    try {
      const response = await removeSavedProduct(productId, token);
      if (response.success) {
        setFavoriteProducts(prevProducts => prevProducts.filter(product => product.id !== productId));
      } else {
        throw new Error(response.error || 'Failed to remove product');
      }
    } catch (err) {
      console.error('Error removing product:', err);
      setError('Failed to remove product. Please try again.');
    } finally {
      setRemovingProductIds(prev => {
        const newSet = new Set(prev);
        newSet.delete(productId);
        return newSet;
      });
    }
  };

  const formatRating = (rating) => {
    const stars = [];
    const fullStars = Math.floor(rating);
    const halfStar = rating - fullStars >= 0.5;
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

    for (let i = 0; i < fullStars; i++) {
      stars.push(<Star key={`full-${i}`} className="text-yellow-400 w-4 h-4" />);
    }

    if (halfStar) {
      stars.push(
        <Star 
          key="half" 
          className="text-yellow-400 w-4 h-4" 
          style={{ clipPath: 'inset(0 50% 0 0)' }} 
        />
      );
    }

    for (let i = 0; i < emptyStars; i++) {
      stars.push(<Star key={`empty-${i}`} className="text-gray-300 w-4 h-4" />);
    }

    return <div className="flex items-center">{stars}</div>;
  };

  // Filter logic adjusted to use 'product_type'
  const filteredProducts = favoriteProducts.filter(product => {
    if (filter === 'all') return true;
    return product.product_type.toLowerCase() === filter;
  });

  // Sort logic adjusted to use correct property names and handle data types
  const sortedProducts = [...filteredProducts].sort((a, b) => {
    if (sort === 'name') {
      const nameA = a.product_name || ''; // Default to empty string if undefined
      const nameB = b.product_name || ''; // Default to empty string if undefined
      return nameA.localeCompare(nameB);
    } else if (sort === 'rating') {
      const ratingA = parseFloat(a.product_rating) || 0; // Convert to number, default to 0
      const ratingB = parseFloat(b.product_rating) || 0; // Convert to number, default to 0
      return ratingB - ratingA;
    }
    return 0;
  });

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-primary"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-500">
        {error}
      </div>
    );
  }

  if (favoriteProducts.length === 0) {
    return (
      <div className="text-center text-gray-700">
        You have no favorite products yet.
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Filters */}
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-4 md:space-y-0">
        <div>
          <label className="mr-2 font-medium">Filter by Category:</label>
          <select
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            className="border border-gray-300 rounded p-2"
          >
            <option value="all">All</option>
            {/* Add more categories as needed */}
            <option value="moisturizer">Moisturizer</option>
            <option value="cleanser">Cleanser</option>
            <option value="serum">Serum</option>
          </select>
        </div>
        <div>
          <label className="mr-2 font-medium">Sort by:</label>
          <select
            value={sort}
            onChange={(e) => setSort(e.target.value)}
            className="border border-gray-300 rounded p-2"
          >
            <option value="name">Name</option>
            <option value="rating">Rating</option>
          </select>
        </div>
      </div>

      {/* Products List */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {sortedProducts.map(product => (
          <motion.div
            key={product.id}
            className="bg-white rounded-lg shadow p-4 flex flex-col"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
          >
            <img 
              src={product.product_image_link} 
              alt={product.product_name || 'Product'} 
              className="w-full h-40 object-cover rounded-md mb-4" 
            />
            <h3 className="text-lg font-semibold mb-2">{product.product_name || 'Unnamed Product'}</h3>
            <p className="text-gray-600 mb-2">{product.product_description || 'No description available.'}</p>
            {formatRating(parseFloat(product.product_rating))}
            <div className="mt-auto flex justify-between items-center">
              <a 
                href={product.product_link} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="flex items-center text-primary hover:underline"
              >
                View Product <ExternalLink className="ml-1 w-4 h-4" />
              </a>
              <button
                onClick={() => handleRemove(product.id)}
                className={`flex items-center text-red-500 hover:text-red-700 ${
                  removingProductIds.has(product.id) ? 'cursor-not-allowed opacity-50' : ''
                }`}
                disabled={removingProductIds.has(product.id)}
              >
                <Trash2 className="w-4 h-4 mr-1" /> 
                {removingProductIds.has(product.id) ? 'Removing...' : 'Remove'}
              </button>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default FavoriteProducts;
