import React from 'react';
import { motion } from 'framer-motion';
import { Heart, Users, Sparkles, Globe } from 'lucide-react';

const AboutUs = () => {
  const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 }
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-100 via-purple-100 to-pink-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <motion.h1 
          className="text-4xl font-bold text-center text-gray-800 mb-8"
          {...fadeIn}
        >
          About Skinmingle
        </motion.h1>

        <motion.section className="bg-white rounded-xl shadow-lg p-8 mb-8" {...fadeIn}>
          <h2 className="text-2xl font-semibold mb-4 text-primary">Our Mission</h2>
          <p className="text-gray-600 mb-4">
            At Skinmingle, we're on a mission to revolutionize skincare through the power of AI. 
            We believe that everyone deserves a personalized skincare routine that's as unique as they are.
          </p>
          <p className="text-gray-600">
            Our goal is to make expert-level skincare advice accessible to everyone, 
            helping you achieve your best skin ever with tailored recommendations and cutting-edge technology.
          </p>
        </motion.section>

        <motion.section className="grid md:grid-cols-2 gap-8 mb-8" {...fadeIn}>
          <div className="bg-white rounded-xl shadow-lg p-6">
            <Heart className="w-12 h-12 text-primary mb-4" />
            <h3 className="text-xl font-semibold mb-2">Personalized Care</h3>
            <p className="text-gray-600">
              We understand that every skin is unique. Our AI-powered platform delivers 
              custom-tailored skincare routines and product recommendations just for you.
            </p>
          </div>
          <div className="bg-white rounded-xl shadow-lg p-6">
            <Users className="w-12 h-12 text-primary mb-4" />
            <h3 className="text-xl font-semibold mb-2">Community-Driven</h3>
            <p className="text-gray-600">
              Join a thriving community of skincare enthusiasts. Share experiences, 
              get support, and celebrate your skincare journey together.
            </p>
          </div>
          <div className="bg-white rounded-xl shadow-lg p-6">
            <Sparkles className="w-12 h-12 text-primary mb-4" />
            <h3 className="text-xl font-semibold mb-2">Cutting-Edge Innovation</h3>
            <p className="text-gray-600">
              We're constantly pushing the boundaries of what's possible in skincare technology, 
              bringing you the latest advancements in AI and dermatological science.
            </p>
          </div>
          <div className="bg-white rounded-xl shadow-lg p-6">
            <Globe className="w-12 h-12 text-primary mb-4" />
            <h3 className="text-xl font-semibold mb-2">Global Impact</h3>
            <p className="text-gray-600">
              Our vision extends beyond individual care. We're committed to making 
              a positive impact on global skincare education and accessibility.
            </p>
          </div>
        </motion.section>

        <motion.section className="bg-white rounded-xl shadow-lg p-8" {...fadeIn}>
          <h2 className="text-2xl font-semibold mb-4 text-primary">Our Story</h2>
          <p className="text-gray-600 mb-4">
            Skinmingle was born from a simple idea: what if everyone could have access to 
            personalized, expert-level skincare advice at their fingertips? Our founders, 
            a team of dermatologists, data scientists, and tech innovators, came together 
            to make this vision a reality.
          </p>
          <p className="text-gray-600">
            Since our launch, we've helped thousands of users transform their skin and boost 
            their confidence. But we're just getting started. Join us as we continue to 
            innovate and shape the future of skincare.
          </p>
        </motion.section>
      </div>
    </div>
  );
};

export default AboutUs;