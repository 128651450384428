// src/components/CustomSelect.js

import React from 'react';
import Select from 'react-select';
import ReactDOM from 'react-dom';

const CustomSelect = ({ options, value, onChange, placeholder }) => {
  return (
    <Select
      options={options}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      styles={{
        menuPortal: (base) => ({ ...base, zIndex: 70 }),
        menu: (base) => ({ ...base, zIndex: 70 }),
      }}
      menuPortalTarget={document.body}
      className="react-select-container"
      classNamePrefix="react-select"
    />
  );
};

export default CustomSelect;
