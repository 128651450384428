// src/layouts/ChatbotLayout.js
import React from 'react';
import Navigation from '../components/Navigation/Navigation';

function ChatbotLayout({ children }) {
  return (
    <div className="flex flex-col h-screen">
      <header className="bg-white shadow-sm">
          <Navigation />
      </header>
      <main className="flex-1 overflow-hidden">
        {children}
      </main>
    </div>
  );
}

export default ChatbotLayout;