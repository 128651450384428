//src/pages/LandingPage/LandingPage.js
import React, { useState } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { MessageCircle, Zap, Shield, Brain, ChevronDown } from 'lucide-react';

const LandingPage = () => {
  const navigate = useNavigate();
  const { scrollYProgress } = useScroll();
  const opacityHero = useTransform(scrollYProgress, [0, 0.2], [1, 0]);
  const opacityFeatures = useTransform(scrollYProgress, [0.1, 0.3], [0, 1]);
  const opacityBenefits = useTransform(scrollYProgress, [0.25, 0.45], [0, 1]);
  const opacityWhy = useTransform(scrollYProgress, [0.4, 0.6], [0, 1]);
  const opacityFAQ = useTransform(scrollYProgress, [0.55, 0.75], [0, 1]);
  const opacityCTA = useTransform(scrollYProgress, [0.7, 0.9], [0, 1]);

  const handleGetStarted = () => {
    navigate('/home');
  };

  const faqs = [
    {
      question: "What is SkinMingle?",
      answer: "SkinMingle offers personalized skincare routines and product recommendations tailored to your unique skin needs. We gather information through a simple questionnaire and utilize AI to craft a skincare plan just for you.",
    },
    {
      question: "How does SkinMingle personalize advice?",
      answer: "We analyze your responses from the questionnaire along with ongoing interactions to understand your unique skin type, concerns, and environment, providing tailored advice and recommendations powered by our AI.",
    },
    {
      question: "Is my personal data secure?",
      answer: "Yes, your personal data is securely protected and will not be shared with third parties. We may use aggregated, non-personally identifiable information for market research purposes and to improve our services.",
    },
    {
      question: "How do I get started?",
      answer: "Simply click the 'Get Started Now' button to begin your personalized skincare journey. You'll start by completing a quick questionnaire to help us understand your skin.",
    },
  ];

  const [openFAQIndex, setOpenFAQIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenFAQIndex(openFAQIndex === index ? null : index);
  };

  return (
    <div className="min-h-screen bg-background text-text">
      {/* Hero Section */}
      <motion.header
        style={{ opacity: opacityHero }}
        className="relative h-screen flex items-center justify-center overflow-hidden"
      >
        <div className="absolute inset-0 z-0">
          <img
            src="/images/radiant_dewy_skin_with_water_droplets.png"
            alt="Radiant skin"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-black opacity-30"></div>
        </div>
        <div className="container mx-auto px-4 text-center relative z-10">
          <motion.h1
            className="text-5xl md:text-7xl font-bold mb-6 text-white drop-shadow-lg"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            Unlock Your Best Skin Yet
          </motion.h1>
          <motion.p
            className="text-xl md:text-2xl mb-8 text-white drop-shadow"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            Personalized skincare routines crafted just for you
          </motion.p>
          <motion.button
            onClick={handleGetStarted}
            className="bg-primary text-white hover:bg-primary-dark font-bold py-3 px-8 rounded-full text-lg transition duration-300 shadow-lg"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Start Your Personalized Journey
          </motion.button>
        </div>
      </motion.header>

      <main className="container mx-auto px-4 py-16">
        {/* Features Section */}
        <motion.section className="mb-24" style={{ opacity: opacityFeatures }}>
          <h2 className="text-4xl font-bold mb-12 text-center">How It Works</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {[
              {
                icon: Brain,
                title: "Tell Us About You",
                description: "Complete a quick questionnaire to share your skin's story. Our AI gets to know your unique needs.",
              },
              {
                icon: MessageCircle,
                title: "Get Expert Advice",
                description: "Receive personalized skincare guidance anytime, powered by AI.",
              },
              {
                icon: Zap,
                title: "Custom Routines",
                description: "Access routines tailored to your unique needs.",
              },
              {
                icon: Shield,
                title: "Product Matches",
                description: "Discover products that truly work for you.",
              },
            ].map((feature, index) => (
              <motion.div
                key={index}
                className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <feature.icon className="w-16 h-16 text-primary mb-6" />
                <h3 className="text-2xl font-semibold mb-4">{feature.title}</h3>
                <p className="text-gray-600">{feature.description}</p>
              </motion.div>
            ))}
          </div>
        </motion.section>

        {/* Benefits Section */}
        <motion.section className="mb-24" style={{ opacity: opacityBenefits }}>
          <div className="bg-white p-12 rounded-xl shadow-lg text-center relative overflow-hidden">
            <div className="absolute inset-0 opacity-25">
              <img
                src="/images/landingPage1.png"
                alt="Skincare products"
                className="w-full h-full object-cover"
              />
            </div>
            <div className="relative z-10">
              <h2 className="text-4xl font-bold mb-8">Experience the Difference</h2>
              <p className="text-xl mb-8">
                Join thousands who have transformed their skincare routine with SkinMingle.
              </p>
              <ul className="text-left inline-block mb-8">
                <li className="flex items-center mb-4">
                  <Brain className="w-6 h-6 text-primary mr-4" />
                  <span>Personalized care powered by AI</span>
                </li>
                <li className="flex items-center mb-4">
                  <Shield className="w-6 h-6 text-primary mr-4" />
                  <span>Trusted recommendations tailored to your needs</span>
                </li>
                <li className="flex items-center">
                  <MessageCircle className="w-6 h-6 text-primary mr-4" />
                  <span>Support whenever you need it</span>
                </li>
              </ul>
              <motion.button
                onClick={handleGetStarted}
                className="bg-primary text-white hover:bg-primary-dark font-bold py-4 px-5 rounded-full text-xl transition duration-300 shadow-lg"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Start Your Journey
              </motion.button>
            </div>
          </div>
        </motion.section>

        {/* Why Choose Us Section */}
        <motion.section className="mb-24" style={{ opacity: opacityWhy }}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
            <div>
              <img
                src="/images/landingPage2.png"
                alt="AI-powered skincare"
                className="rounded-xl shadow-lg"
              />
            </div>
            <div>
              <h2 className="text-4xl font-bold mb-8">Why SkinMingle?</h2>
              <ul className="space-y-6">
                <li className="flex items-start">
                  <Brain className="w-6 h-6 text-primary mr-4 mt-1" />
                  <div>
                    <h3 className="text-xl font-semibold mb-2">Powered by AI Technology</h3>
                    <p className="text-gray-600">
                      Our AI adapts to your skin's changing needs over time, ensuring you always get the best advice.
                    </p>
                  </div>
                </li>
                <li className="flex items-start">
                  <MessageCircle className="w-6 h-6 text-primary mr-4 mt-1" />
                  <div>
                    <h3 className="text-xl font-semibold mb-2">Expert Support</h3>
                    <p className="text-gray-600">
                      Get guidance and advice whenever you need it from our AI-powered assistant.
                    </p>
                  </div>
                </li>
                <li className="flex items-start">
                  <Zap className="w-6 h-6 text-primary mr-4 mt-1" />
                  <div>
                    <h3 className="text-xl font-semibold mb-2">Easy and Convenient</h3>
                    <p className="text-gray-600">
                      Access your personalized routine and recommendations anytime, anywhere.
                    </p>
                  </div>
                </li>
                <li className="flex items-start">
                  <Shield className="w-6 h-6 text-primary mr-4 mt-1" />
                  <div>
                    <h3 className="text-xl font-semibold mb-2">Trusted Recommendations</h3>
                    <p className="text-gray-600">
                      Discover products that have been carefully selected to suit your skin.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </motion.section>

        {/* FAQ Section */}
        <motion.section className="mb-24" style={{ opacity: opacityFAQ }}>
          <h2 className="text-4xl font-bold mb-12 text-center">Frequently Asked Questions</h2>
          <div className="max-w-3xl mx-auto space-y-4">
            {faqs.map((faq, index) => (
              <div key={index} className="bg-white rounded-lg shadow-md">
                <button
                  onClick={() => toggleFAQ(index)}
                  className="w-full text-left px-6 py-4 flex items-center justify-between focus:outline-none"
                >
                  <span className="text-2xl font-semibold">{faq.question}</span>
                  <ChevronDown
                    className={`w-6 h-6 transform transition-transform ${
                      openFAQIndex === index ? 'rotate-180' : ''
                    }`}
                  />
                </button>
                {openFAQIndex === index && (
                  <div className="px-6 pb-4">
                    <p className="text-gray-600">{faq.answer}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </motion.section>

        {/* Call to Action Section */}
        <motion.section className="text-center mb-24" style={{ opacity: opacityCTA }}>
          <h2 className="text-4xl font-bold mb-8">Ready to Embrace Healthier Skin?</h2>
          <motion.button
            onClick={handleGetStarted}
            className="bg-primary text-white hover:bg-primary-dark font-bold py-4 px-10 rounded-full text-xl transition duration-300 shadow-lg"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Get Started Now
          </motion.button>
        </motion.section>
      </main>
    </div>
  );
};

export default LandingPage;
