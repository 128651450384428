// src/pages/ChatbotPage/ChatbotPage.js
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext'; 
import { motion, AnimatePresence } from 'framer-motion';
import ChatWindow from '../../components/Chatbot/ChatWindow';
import ProductSidebar from '../../components/Chatbot/ProductSidebar'; 
import { ShoppingBag } from 'lucide-react';
import QuickFeedback from '../../components/feedbacks/QuickFeedback'; 
import ChatbotIntroModal from '../../components/Chatbot/ChatbotIntroModal'; // Import the intro modal component
import QuestionnaireModal from '../../components/QuestionnaireModal'; // Import the questionnaire modal component
import { updateUserData, updateQuestionnaire } from '../../services/api'; // Import API functions

function ChatbotPage() {
  const [messages, setMessages] = useState([]);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchingProducts, setIsSearchingProducts] = useState(false); // New state
  const [showSidebar, setShowSidebar] = useState(false);
  const [productsAvailable, setProductsAvailable] = useState(false);
  const { currentUser, userData, setUserData } = useAuth(); 
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showIntroModal, setShowIntroModal] = useState(false);
  const [showQuestionnaireModal, setShowQuestionnaireModal] = useState(false);
  const [error, setError] = useState('');
  const chatEndRef = useRef(null);
  const isInitialRender = useRef(true);

  // Handle window resize for responsive design
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Fetch chat history on component mount or when currentUser changes
  useEffect(() => {
    fetchChatHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  // Show intro modal if the user hasn't seen it before
  useEffect(() => {
    if (userData && !userData.hasSeenChatbotIntro) {
      setShowIntroModal(true);
    }
  }, [userData]);

  const fetchChatHistory = async () => {
    if (!currentUser) return;

    try {
      setIsLoading(true);
      const token = await currentUser.getIdToken();
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/chats`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch chat history');
      }

      const data = await response.json();
      const formattedChatHistory = data.chat_history.map(msg => ({
        role: msg.role,
        content: msg.content,
        isComplete: true, // Assuming history messages are complete
      }));
      setMessages(formattedChatHistory);
    } catch (error) {
      console.error('Error fetching chat history:', error);
      setError('Failed to load chat history.');
    } finally {
      setIsLoading(false);
    }
  };

  const sendMessage = async (inputMessage) => {
    if (!inputMessage.trim() || !currentUser) return; // Removed isLoading from condition
  
    setIsLoading(true);
    setProductsAvailable(false);
    setIsSearchingProducts(false); // Reset before starting
    const newMessage = { role: 'user', content: inputMessage };
    setMessages(prev => [...prev, newMessage]);
  
    try {
      const token = await currentUser.getIdToken();
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/chats`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          message: inputMessage,
        })
      });
  
      if (!response.ok) throw new Error('Network response was not ok');
  
      const reader = response.body.getReader();
      let assistantMessage = '';
      let buffer = '';
  
      const processJSON = (jsonString) => {
        try {
          const data = JSON.parse(jsonString);
          switch (data.type) {
            case 'message':
              assistantMessage += data.content;
              setMessages(prev => {
                const updated = [...prev];
                const lastMessageIndex = updated.findIndex(msg => msg.role === 'assistant' && !msg.isComplete);
                if (lastMessageIndex !== -1) {
                  // Update existing assistant message
                  updated[lastMessageIndex].content = assistantMessage;
                } else {
                  // Add new assistant message
                  updated.push({ role: 'assistant', content: assistantMessage, isComplete: false });
                }
                return updated;
              });
              break;
            case 'function_call':
              if (data.function.name === 'get_products') {
                // Assistant is searching for products
                setIsSearchingProducts(true);
              }
              break;
            case 'product_details':
              setProducts(data.content);
              setShowSidebar(true);
              setProductsAvailable(true);
              setIsSearchingProducts(false); // Reset after products are received
              break;
            case 'end':
              setIsLoading(false);
              setIsSearchingProducts(false); // Ensure it's reset
              // Mark the assistant message as complete
              setMessages(prev => {
                const updated = [...prev];
                const lastMessageIndex = updated.findIndex(msg => msg.role === 'assistant' && !msg.isComplete);
                if (lastMessageIndex !== -1) {
                  updated[lastMessageIndex].isComplete = true;
                }
                return updated;
              });
              break;
            case 'error':
              console.error('Error from server:', data.message);
              setMessages(prev => [...prev, { role: 'assistant', content: 'Sorry, an error occurred. Please try again.', isComplete: true }]);
              setIsLoading(false);
              setIsSearchingProducts(false); // Ensure it's reset
              break;
            default:
              break;
          }
        } catch (parseError) {
          console.error('Error parsing JSON:', parseError, 'Raw data:', jsonString);
        }
      };
  
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
  
        const chunk = new TextDecoder().decode(value);
        buffer += chunk;
  
        let newlineIndex;
        while ((newlineIndex = buffer.indexOf('\n')) !== -1) {
          const line = buffer.slice(0, newlineIndex);
          buffer = buffer.slice(newlineIndex + 1);
          if (line.trim() !== '') {
            processJSON(line);
          }
        }
      }
  
      if (buffer.trim() !== '') {
        processJSON(buffer);
      }
  
    } catch (error) {
      console.error('Error in chat:', error);
      setMessages(prev => [...prev, { role: 'assistant', content: 'Sorry, an error occurred. Please try again.', isComplete: true }]);
    } finally {
      setIsLoading(false);
      setIsSearchingProducts(false); // Ensure it's reset
    }
  };
  


  const clearChat = async () => {
    if (!currentUser) return;

    try {
      const token = await currentUser.getIdToken();
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/chats`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to clear chat');
      }

      setMessages([]);
      setProducts([]);
      setShowSidebar(false);
      setProductsAvailable(false);
    } catch (error) {
      console.error('Error clearing chat:', error);
      setError('Failed to clear chat.');
    }
  };

  // Function to handle sending suggested queries from intro modal
  const handleSendSuggestedQuery = (query) => {
    sendMessage(query);
  };

  // Function to handle closing the intro modal and updating user data
  const handleIntroModalClose = async () => {
    try {
      const updatedData = await updateUserData({ hasSeenChatbotIntro: true });
      setUserData({ ...userData, hasSeenChatbotIntro: true }); // Update userData in context
      setShowIntroModal(false);
    } catch (error) {
      console.error('Error updating user data:', error);
      setError('Failed to update onboarding status.');
    }
  };

  // Function to handle completion of the questionnaire
  const handleQuestionnaireComplete = async (questionnaireData) => {
    try {
      const updatedData = await updateQuestionnaire(questionnaireData);
      setUserData({ ...userData, ...updatedData });
      setShowQuestionnaireModal(false);
      // Optionally, you can send a message to the chatbot with the updated data
    } catch (error) {
      console.error('Error updating questionnaire data:', error);
      setError('Failed to update questionnaire.');
    }
  };

  // Function to handle showing the intro modal via help button
  const handleShowIntro = () => {
    setShowIntroModal(true);
  };

  return (
    <div className="flex flex-col h-screen bg-gradient-to-r from-blue-100 via-purple-100 to-pink-100 relative">
      {/* Header */}
      <div className="bg-white shadow-sm">
        <div className="container mx-auto px-4">
          <div className="flex justify-between items-center py-4">
            <Link to="/" className="flex items-center">
              <img 
                src="/images/logo/black_logo.png" 
                alt="SkinMingle Logo" 
                className="h-8 w-auto mr-2"
              />
            </Link>
            {/* Add any additional header items here */}
          </div>
        </div>
      </div>

      {/* Main content area */}
      <div className="flex-1 flex overflow-hidden relative">
        <div className="flex-1 flex flex-col">
          <ChatWindow 
            messages={messages}
            isLoading={isLoading}
            onSendMessage={sendMessage}
            onClearChat={clearChat}
            productsAvailable={productsAvailable}
            isMobile={isMobile}
            QuickFeedbackComponent={QuickFeedback} 
            onShowIntro={handleShowIntro}
            onShowQuestionnaire={() => setShowQuestionnaireModal(true)} // Pass the function to show the questionnaire
            isSearchingProducts={isSearchingProducts} // Pass isSearchingProducts as prop
          />
        </div>

        <AnimatePresence>
          {showSidebar && (
            <motion.div
              initial={{ x: '100%' }}
              animate={{ x: 0 }}
              exit={{ x: '100%' }}
              transition={{ type: 'spring', stiffness: 300, damping: 30 }}
              className={`bg-white z-50 ${isMobile ? 'fixed inset-0' : 'relative w-96'}`}
            >
              <ProductSidebar 
                products={products}
                onClose={() => setShowSidebar(false)}
              />
            </motion.div>
          )}
        </AnimatePresence>

        {productsAvailable && !showSidebar && (
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={() => setShowSidebar(true)}
            className="absolute bottom-20 right-4 bg-primary text-white p-3 rounded-full shadow-lg z-10"
          >
            <ShoppingBag size={24} />
          </motion.button>
        )}
      </div>

      {/* Intro Modal */}
      <AnimatePresence>
        {showIntroModal && (
          <ChatbotIntroModal 
            onClose={handleIntroModalClose}
            onSendSuggestedQuery={handleSendSuggestedQuery}
          />
        )}
      </AnimatePresence>

      {/* Questionnaire Modal */}
      <AnimatePresence>
        {showQuestionnaireModal && (
          <QuestionnaireModal 
            initialData={userData}
            onComplete={handleQuestionnaireComplete}
            onClose={() => setShowQuestionnaireModal(false)}
          />
        )}
      </AnimatePresence>

      {/* Display error message if any */}
      {error && (
        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 bg-red-500 text-white px-4 py-2 rounded">
          {error}
        </div>
      )}
    </div>
  );
}

export default ChatbotPage;
