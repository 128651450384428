// src/pages/HomePage/HomePage.js

import React, { useState, useEffect } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { getUserData } from '../../services/api';
import EnhanceSkinCareJourney from './EnhanceSkinCareJourney';
import {
  ClipboardList,
  Heart,
  Mail,
  ArrowRight,
  Brain,
  Shield,
  TrendingUp,
  AlertTriangle,
} from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';

const HomePage = () => {
  const navigate = useNavigate();
  const { currentUser, userData, setUserData } = useAuth();
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const { scrollYProgress } = useScroll();

  const opacityHero = useTransform(scrollYProgress, [0, 0.2], [1, 0]);
  const opacityTasks = useTransform(scrollYProgress, [0.1, 0.3], [0, 1]);
  const opacityFeatures = useTransform(scrollYProgress, [0.2, 0.4], [0, 1]);
  const opacityCTA = useTransform(scrollYProgress, [0.7, 0.9], [0, 1]);

  useEffect(() => {
    const fetchUserDataWrapper = async () => {
      try {
        const data = await getUserData();
        setUserData(data.user);
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserDataWrapper();
  }, [setUserData]);

  const handleStartChat = () => {
    navigate('/chatbot');
  };

  const handleUpdateProfile = () => {
    navigate('/account', { state: { activeSection: 'mySkin' } });
  };

  const handleSetupNewsletter = () => {
    navigate('/account', { state: { activeSection: 'settings' } });
  };

  const handleSaveProduct = () => {
    navigate('/chatbot', { state: { action: 'saveProduct' } });
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gradient-to-r from-blue-100 via-purple-100 to-pink-100">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (!userData) {
    return (
      <div className="flex justify-center items-center h-screen bg-gradient-to-r from-blue-100 via-purple-100 to-pink-100">
        <div className="text-center">
          <h2 className="text-2xl font-bold mb-4">Oops! Something went wrong.</h2>
          <p className="mb-4">We couldn't load your user data. Please try again later.</p>
          <button
            onClick={() => window.location.reload()}
            className="bg-primary text-white px-4 py-2 rounded-full hover:bg-primary-dark transition duration-300"
          >
            Refresh Page
          </button>
        </div>
      </div>
    );
  }

  const isQuestionnaireComplete =
    userData.questionnaire && Object.keys(userData.questionnaire).length > 0;
  const isNewsletterSetup = userData.preferences?.newsletter === true;
  const hasAnyProductSaved = userData.savedProducts && userData.savedProducts.length > 0;

  const tasks = [
    {
      name: 'Complete Your Skin Profile',
      description: 'Tell us about your skin type and concerns',
      completed: isQuestionnaireComplete,
      icon: ClipboardList,
      action: handleUpdateProfile,
      benefits: [
        'Get personalized product recommendations',
        'Receive a tailored skincare routine',
        "Track your skin's progress over time",
      ],
      className: 'task-complete-profile',
    },
    {
      name: 'Set Up the Newsletter',
      description: 'Stay updated with the latest skincare tips',
      completed: isNewsletterSetup,
      icon: Mail,
      action: handleSetupNewsletter,
      benefits: [
        'Receive exclusive skincare advice',
        'Learn about new product launches',
        'Get personalized content based on your skin profile',
      ],
      className: 'task-setup-newsletter',
    },
    {
      name: 'Save Your First Product',
      description: 'Start building your personalized product collection',
      completed: hasAnyProductSaved,
      icon: Heart,
      action: handleSaveProduct,
      benefits: [
        'Keep track of products you love',
        'Get reminders for repurchasing',
        'Receive similar product recommendations',
      ],
      className: 'task-save-product',
    },
  ];

  const completedTasks = tasks.filter((task) => task.completed).length;
  const isChatAvailable = isQuestionnaireComplete;

  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-100 via-purple-100 to-pink-100 text-gray-800">
      {/* Hero Section */}
      <motion.section
        style={{ opacity: opacityHero }}
        className="relative min-h-screen md:h-auto md:py-20 flex items-center overflow-hidden"
      >
        <div className="absolute inset-0 z-0">
          {/* Replace with your background image */}
          <img
            src="/images/luxurious_spa_setting_2.png"
            alt="Luxurious skincare background"
            className="w-full h-full object-cover opacity-40"
          />
        </div>
        <div className="container mx-auto px-4 relative z-10">
          <div className="max-w-3xl">
            <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-4">
              Welcome back, {userData.displayName}!
            </h1>
            <p className="text-xl text-gray-700 mb-8">
              Your AI-powered skincare journey awaits. Let's get started!
            </p>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="chatbot-button bg-primary hover:bg-primary-dark text-white font-bold py-3 px-8 rounded-full text-lg transition duration-300 shadow-lg"
              onClick={handleStartChat}
            >
              Chat with Your Skincare AI <ArrowRight className="inline ml-2" />
            </motion.button>
            {!isQuestionnaireComplete && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.3 }}
                className="mt-4 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 rounded"
              >
                <AlertTriangle className="inline-block mr-2" />
                Complete your skin profile for more personalized recommendations!
              </motion.div>
            )}
          </div>
        </div>
      </motion.section>

      {/* Enhanced Skincare Journey Section */}
      <motion.div style={{ opacity: opacityTasks }}>
        <EnhanceSkinCareJourney
          tasks={tasks}
          completedTasks={completedTasks}
          isChatAvailable={isChatAvailable}
          onStartChat={handleStartChat}
        />
      </motion.div>

      {/* Feature Highlights */}
      <motion.section
        style={{ opacity: opacityFeatures }}
        className="py-16 bg-gradient-to-r from-blue-50 to-purple-50"
      >
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">
            How Your AI Skincare Assistant Works
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                icon: Brain,
                title: 'AI-Powered Analysis',
                description: 'Get a detailed skin assessment and personalized recommendations',
              },
              {
                icon: Shield,
                title: 'Adaptive Routines',
                description: 'Receive custom skincare regimens that evolve with your skin',
              },
              {
                icon: TrendingUp,
                title: 'Continuous Learning',
                description: 'Your AI assistant improves with every interaction',
              },
            ].map((feature, index) => (
              <motion.div
                key={index}
                className="bg-white rounded-xl p-6 shadow-lg"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <feature.icon className="w-12 h-12 text-primary mb-4" />
                <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                <p className="text-gray-700">{feature.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </motion.section>

      {/* Call to Action */}
      <motion.section
        style={{ opacity: opacityCTA }}
        className="py-20 bg-gradient-to-r from-blue-400 via-purple-400 to-pink-400 text-white relative overflow-hidden"
      >
        <div className="absolute inset-0 opacity-20">
          {/* Replace with your abstract bubbles image */}
          <img src="/images/molecules.jpg" alt="Abstract bubbles" className="w-full h-full object-cover" />
        </div>
        <div className="container mx-auto px-4 text-center relative z-10">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">
            Ready for Personalized Skincare Advice?
          </h2>
          <p className="text-xl mb-8">
            {isChatAvailable
              ? 'Your AI assistant is fully powered with your skin profile. Get your personalized advice now!'
              : 'Complete your skin profile to unlock the full potential of your AI Skincare Assistant!'}
          </p>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="bg-white text-primary hover:bg-gray-100 font-bold py-4 px-10 rounded-full text-lg transition duration-300 shadow-lg"
            onClick={isChatAvailable ? handleStartChat : handleUpdateProfile}
          >
            {isChatAvailable ? 'Get Personalized Advice' : 'Complete Your Skin Profile'}
          </motion.button>
        </div>
      </motion.section>
    </div>
  );
};

export default HomePage;
