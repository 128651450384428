// src/components/QuestionnaireModal.js

import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Tooltip from './Tooltip'; // Ensure Tooltip component handles clicks
import { Info, X } from 'lucide-react';

const QuestionnaireModal = ({ initialData, onComplete, onClose, isOnboarding = false }) => {
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({
    skinType: '',
    skinSensitivity: '',
    skinConcerns: [],
    currentRoutine: '',
    feelingAfterCleansing: '',
    ...initialData,
  });

  useEffect(() => {
    if (initialData) {
      setFormData(prevData => ({ ...prevData, ...initialData }));
    }
  }, [initialData]);

  const handleChange = e => {
    const { name, value, type, checked } = e.target;
    if (name === 'skinConcerns') {
      setFormData(prevData => ({
        ...prevData,
        skinConcerns: checked
          ? [...prevData.skinConcerns, value]
          : prevData.skinConcerns.filter(item => item !== value),
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: type === 'checkbox' ? checked : value,
      }));
    }
  };

  const handleNext = () => setStep(prevStep => prevStep + 1);
  const handleBack = () => setStep(prevStep => prevStep - 1);

  const handleSubmit = e => {
    e.preventDefault();
    onComplete(formData);
  };

  const isCurrentStepValid = () => {
    switch (step) {
      case 0:
        return formData.skinType !== '';
      case 1:
        return formData.skinSensitivity !== '';
      case 2:
        return formData.skinConcerns.length > 0;
      case 3:
        return formData.currentRoutine !== '';
      case 4:
        return formData.feelingAfterCleansing !== '';
      default:
        return true;
    }
  };

  const progressPercentage = ((step + 1) / 5) * 100;

  const skinTypeOptions = [
    { value: 'Dry', label: 'Dry' },
    { value: 'Balanced', label: 'Balanced' },
    { value: 'Oily', label: 'Oily' },
    { value: 'Combination', label: 'Combination' },
  ];

  const questionnaireSteps = [
    // Step 1: Skin Type
    <motion.div
      key="skinType"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="space-y-6"
    >
      <div className="flex items-center">
        <div className="flex items-center justify-center w-8 h-8 bg-primary text-white rounded-full">
          1
        </div>
        <h2 className="ml-4 text-2xl font-bold text-primary">What is your skin type?</h2>
      </div>
      <div className="space-y-4 mt-4">
        {skinTypeOptions.map(option => (
          <label
            key={option.value}
            className={`flex items-center p-4 border rounded-lg cursor-pointer hover:bg-gray-50 transition ${
              formData.skinType === option.value ? 'border-primary bg-gray-100' : 'border-gray-200'
            }`}
          >
            <input
              type="radio"
              name="skinType"
              value={option.value}
              checked={formData.skinType === option.value}
              onChange={handleChange}
              className="form-radio text-primary h-5 w-5"
            />
            <span className="ml-3 font-medium text-gray-700">{option.label}</span>
            <Tooltip content={getSkinTypeDescription(option.label)}>
              <Info
                className="ml-auto text-gray-400 w-5 h-5 cursor-pointer"
                aria-label={`More info about ${option.label} skin type`}
              />
            </Tooltip>
          </label>
        ))}
      </div>
    </motion.div>,

    // Step 2: Skin Sensitivity
    <motion.div
      key="skinSensitivity"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="space-y-6"
    >
      <div className="flex items-center">
        <div className="flex items-center justify-center w-8 h-8 bg-primary text-white rounded-full">
          2
        </div>
        <h2 className="ml-4 text-2xl font-bold text-primary">How sensitive is your skin?</h2>
      </div>
      <div className="space-y-4 mt-4">
        {['Not sensitive', 'Slightly sensitive', 'Very sensitive'].map(sensitivity => (
          <label
            key={sensitivity}
            className={`flex items-center p-4 border rounded-lg cursor-pointer hover:bg-gray-50 transition ${
              formData.skinSensitivity === sensitivity ? 'border-primary bg-gray-100' : 'border-gray-200'
            }`}
          >
            <input
              type="radio"
              name="skinSensitivity"
              value={sensitivity}
              checked={formData.skinSensitivity === sensitivity}
              onChange={handleChange}
              className="form-radio text-primary h-5 w-5"
            />
            <span className="ml-3 font-medium text-gray-700">{sensitivity}</span>
          </label>
        ))}
      </div>
    </motion.div>,

    // Step 3: Skin Concerns
    <motion.div
      key="skinConcerns"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="space-y-6"
    >
      <div className="flex items-center">
        <div className="flex items-center justify-center w-8 h-8 bg-primary text-white rounded-full">
          3
        </div>
        <h2 className="ml-4 text-2xl font-bold text-primary">What are your primary skin concerns?</h2>
      </div>
      <p className="text-gray-600 mt-2">Select all that apply:</p>
      <div className="space-y-4 mt-4">
        {[
          'Breakouts',
          'Scars',
          'Brightening',
          'Dark Spots',
          'Dryness',
          'Lines & Wrinkles',
          'Saggy & Crepey Skin',
          'Oiliness & Pores',
          'Sensitive & Eczema-Prone',
        ].map(concern => (
          <label
            key={concern}
            className={`flex items-center p-4 border rounded-lg cursor-pointer hover:bg-gray-50 transition ${
              formData.skinConcerns.includes(concern) ? 'border-primary bg-gray-100' : 'border-gray-200'
            }`}
          >
            <input
              type="checkbox"
              name="skinConcerns"
              value={concern}
              checked={formData.skinConcerns.includes(concern)}
              onChange={handleChange}
              className="form-checkbox text-primary h-5 w-5"
            />
            <span className="ml-3 font-medium text-gray-700">{concern}</span>
            <Tooltip content={getSkinConcernDescription(concern)}>
              <Info
                className="ml-auto text-gray-400 w-5 h-5 cursor-pointer"
                aria-label={`More info about ${concern}`}
              />
            </Tooltip>
          </label>
        ))}
      </div>
    </motion.div>,

    // Step 4: Current Routine
    <motion.div
      key="currentRoutine"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="space-y-6"
    >
      <div className="flex items-center">
        <div className="flex items-center justify-center w-8 h-8 bg-primary text-white rounded-full">
          4
        </div>
        <h2 className="ml-4 text-2xl font-bold text-primary">Describe your current skincare routine</h2>
      </div>
      <div className="space-y-4 mt-4">
        {['Minimal', 'Basic', 'Extensive', 'Inconsistent'].map(routine => (
          <label
            key={routine}
            className={`flex items-center p-4 border rounded-lg cursor-pointer hover:bg-gray-50 transition ${
              formData.currentRoutine === routine ? 'border-primary bg-gray-100' : 'border-gray-200'
            }`}
          >
            <input
              type="radio"
              name="currentRoutine"
              value={routine}
              checked={formData.currentRoutine === routine}
              onChange={handleChange}
              className="form-radio text-primary h-5 w-5"
            />
            <span className="ml-3 font-medium text-gray-700">{routine}</span>
            <Tooltip content={getRoutineDescription(routine)}>
              <Info
                className="ml-auto text-gray-400 w-5 h-5 cursor-pointer"
                aria-label={`More info about ${routine} routine`}
              />
            </Tooltip>
          </label>
        ))}
      </div>
    </motion.div>,

    // Step 5: Feeling After Cleansing
    <motion.div
      key="feelingAfterCleansing"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="space-y-6"
    >
      <div className="flex items-center">
        <div className="flex items-center justify-center w-8 h-8 bg-primary text-white rounded-full">
          5
        </div>
        <h2 className="ml-4 text-2xl font-bold text-primary">How does your skin feel after cleansing?</h2>
      </div>
      <div className="space-y-4 mt-4">
        {['Tight and dry', 'Comfortable', 'Still oily'].map(feeling => (
          <label
            key={feeling}
            className={`flex items-center p-4 border rounded-lg cursor-pointer hover:bg-gray-50 transition ${
              formData.feelingAfterCleansing === feeling ? 'border-primary bg-gray-100' : 'border-gray-200'
            }`}
          >
            <input
              type="radio"
              name="feelingAfterCleansing"
              value={feeling}
              checked={formData.feelingAfterCleansing === feeling}
              onChange={handleChange}
              className="form-radio text-primary h-5 w-5"
            />
            <span className="ml-3 font-medium text-gray-700">{feeling}</span>
          </label>
        ))}
      </div>
    </motion.div>,
  ];

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white p-6 sm:p-8 rounded-lg max-w-md w-full max-h-screen overflow-y-auto relative shadow-lg">
        {/* Close Button */}
        {!isOnboarding && (
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
            aria-label="Close Questionnaire"
          >
            <X size={24} />
          </button>
        )}
        {/* Progress Indicator */}
        <div className="mb-6">
          <div className="w-full bg-gray-200 rounded-full h-2">
            <div
              className="bg-primary h-2 rounded-full"
              style={{ width: `${progressPercentage}%` }}
            ></div>
          </div>
          <p className="text-sm text-gray-500 mt-2 text-center">
            Question {step + 1} of {questionnaireSteps.length}
          </p>
        </div>
        {/* Step Content */}
        <AnimatePresence mode="wait">
          {questionnaireSteps[step]}
        </AnimatePresence>
        {/* Navigation Buttons */}
        <div className="flex justify-between mt-8 flex-col sm:flex-row">
          {step > 0 && (
            <button
              onClick={handleBack}
              className="btn bg-gray-300 text-gray-800 hover:bg-gray-400 w-full sm:w-auto mb-4 sm:mb-0"
            >
              Back
            </button>
          )}
          {step < questionnaireSteps.length - 1 ? (
            <button
              onClick={handleNext}
              disabled={!isCurrentStepValid()}
              className={`btn ${
                isCurrentStepValid() ? 'btn-primary' : 'bg-gray-300 text-gray-500 cursor-not-allowed'
              } w-full sm:w-auto`}
            >
              Next
            </button>
          ) : (
            <button
              onClick={handleSubmit}
              disabled={!isCurrentStepValid()}
              className={`btn ${
                isCurrentStepValid() ? 'btn-primary' : 'bg-gray-300 text-gray-500 cursor-not-allowed'
              } w-full sm:w-auto`}
            >
              Complete
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

// Helper functions for tooltip content
const getSkinTypeDescription = (type) => {
  const descriptions = {
    Dry: 'Skin that feels tight, flaky, or rough. It may be prone to irritation and sensitivity.',
    Balanced: 'Also known as "normal" skin. Not too oily or too dry, with few imperfections.',
    Oily: 'Skin that looks shiny and feels greasy. It may be prone to acne and enlarged pores.',
    Combination: 'A mix of oily and dry areas. Typically, the T-zone is oily while cheeks are dry.',
  };
  return descriptions[type] || 'No description available';
};

const getSkinConcernDescription = (concern) => {
  const descriptions = {
    Breakouts: 'Acne, pimples, or blemishes that occur regularly.',
    Scars: 'Marks left on the skin after injuries or severe acne.',
    Brightening: 'Desire for a more radiant, even-toned complexion.',
    'Dark Spots': 'Hyperpigmentation or areas of darker skin.',
    Dryness: 'Lack of moisture in the skin, leading to flakiness or tightness.',
    'Lines & Wrinkles': 'Fine lines or deeper creases in the skin, often associated with aging.',
    'Saggy & Crepey Skin': 'Loss of skin firmness and elasticity.',
    'Oiliness & Pores': 'Excess sebum production and visible or enlarged pores.',
    'Sensitive & Eczema-Prone': 'Skin that easily becomes irritated, red, or itchy.',
  };
  return descriptions[concern] || 'No description available';
};

const getRoutineDescription = (routine) => {
  const descriptions = {
    Minimal: 'Basic cleansing and maybe moisturizing, with few products.',
    Basic: 'Regular cleansing, toning, and moisturizing, possibly with a few targeted treatments.',
    Extensive: 'A multi-step routine with various products for different skin concerns.',
    Inconsistent: 'Skincare habits that vary day to day without a set routine.',
  };
  return descriptions[routine] || 'No description available';
};

export default QuestionnaireModal;
