import React, { useState, useEffect, useMemo } from 'react';
import { Star, Heart, ExternalLink, X, ShoppingBag, ChevronDown, ChevronUp, Search } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useAuth } from '../../contexts/AuthContext';
import { getAnalytics, logEvent } from "firebase/analytics";

const FilterDropdown = ({ title, options, selectedOptions, setSelectedOptions }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const filteredOptions = options.filter(option =>
    option.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="mb-4 relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full flex justify-between items-center px-4 py-2 bg-white border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
      >
        <span>{title} ({selectedOptions.length} selected)</span>
        {isOpen ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
      </button>
      {isOpen && (
        <div className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
          <div className="sticky top-0 bg-white p-2">
            <div className="relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={16} />
              <input
                type="text"
                className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-primary focus:border-primary sm:text-sm"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
          {filteredOptions.map((option) => (
            <div
              key={option}
              className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100"
              onClick={() => {
                setSelectedOptions(prev =>
                  prev.includes(option) ? prev.filter(i => i !== option) : [...prev, option]
                );
              }}
            >
              <input
                type="checkbox"
                checked={selectedOptions.includes(option)}
                onChange={() => {}}
                className="h-4 w-4 text-primary focus:ring-primary border-gray-300 rounded"
              />
              <label className="ml-3 block text-sm font-medium text-gray-700">
                {option}
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const ProductSidebar = ({ products, onClose }) => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [savedProducts, setSavedProducts] = useState(new Set());
  const [compareProducts, setCompareProducts] = useState([]);
  const [selectedProductType, setSelectedProductType] = useState('All');
  const [selectedIssues, setSelectedIssues] = useState([]);
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const { token } = useAuth();
  const analytics = getAnalytics();

  const productTypes = useMemo(() => {
    const types = new Set(products.map(p => p.product_type));
    return ['All', ...Array.from(types)];
  }, [products]);

  const filteredProducts = useMemo(() => {
    return products.filter(product => {
      const typeMatch = selectedProductType === 'All' || product.product_type === selectedProductType;
      const issuesMatch = selectedIssues.length === 0 || 
        (product.baseline_info && product.baseline_info.issues && 
         selectedIssues.every(issue => product.baseline_info.issues.includes(issue)));
      const ingredientsMatch = selectedIngredients.length === 0 || 
        (product.baseline_info && product.baseline_info.ingredients && 
         selectedIngredients.every(ingredient => product.baseline_info.ingredients.includes(ingredient)));
      return typeMatch && issuesMatch && ingredientsMatch;
    });
  }, [products, selectedProductType, selectedIssues, selectedIngredients]);

  const availableIssues = useMemo(() => {
    const issues = new Set();
    filteredProducts.forEach(product => {
      if (product.baseline_info && product.baseline_info.issues) {
        product.baseline_info.issues.forEach(issue => issues.add(issue));
      }
    });
    return Array.from(issues);
  }, [filteredProducts]);

  const availableIngredients = useMemo(() => {
    const ingredients = new Set();
    filteredProducts.forEach(product => {
      if (product.baseline_info && product.baseline_info.ingredients) {
        product.baseline_info.ingredients.forEach(ingredient => ingredients.add(ingredient));
      }
    });
    return Array.from(ingredients);
  }, [filteredProducts]);

  useEffect(() => {
    const fetchSavedProducts = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/saved-products`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (!response.ok) throw new Error('Failed to fetch saved products');
        const data = await response.json();
        setSavedProducts(new Set(data.saved_products));
      } catch (error) {
        console.error('Error fetching saved products:', error);
      }
    };

    if (token) {
      fetchSavedProducts();
    }
  }, [token]);

  useEffect(() => {
    // Reset selected issues and ingredients when product type changes
    setSelectedIssues([]);
    setSelectedIngredients([]);
  }, [selectedProductType]);

  const encodeProductId = (productId) => {
    return btoa(unescape(encodeURIComponent(productId))).replace(/=/g, '');
  };

  const handleProductClick = (product) => {
    setSelectedProduct(product.id === selectedProduct ? null : product.id);
    logEvent(analytics, 'product_click', {
      product_id: product.id,
      product_name: product.product_name,
      product_category: product.product_type
    });
  };

  const handleExternalLinkClick = (product) => {
    logEvent(analytics, 'external_link_click', {
      product_id: product.id,
      product_name: product.product_name,
      destination: product.product_link
    });
  };

  const handleCompareToggle = (product) => {
    setCompareProducts(prev => 
      prev.some(p => p.id === product.id)
        ? prev.filter(p => p.id !== product.id)
        : [...prev, product].slice(-3)
    );
  };

  const handleSaveProduct = async (productId) => {
    const encodedProductId = encodeProductId(productId);
    const isCurrentlySaved = isProductSaved(productId);

    try {
      let response;
      if (isCurrentlySaved) {
        // If the product is already saved, remove it
        response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/saved-products`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({ product_id: productId }),
        });
      } else {
        // If the product is not saved, save it
        response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/saved-products`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({ product_id: productId }),
        });
      }

      if (!response.ok) throw new Error('Failed to update saved product');

      const data = await response.json();

      setSavedProducts(prevSaved => {
        const newSaved = new Set(prevSaved);
        if (isCurrentlySaved) {
          newSaved.delete(encodedProductId);
        } else if (data.is_saved) {
          newSaved.add(encodedProductId);
        }
        return newSaved;
      });

      // Optionally, you can display a message based on the action
      // For example:
      // alert(data.message);
    } catch (error) {
      console.error('Error updating saved product:', error);
      // Optionally, handle the error in the UI
    }
  };

  const isProductSaved = (productId) => {
    const encodedProductId = encodeProductId(productId);
    return savedProducts.has(encodedProductId);
  };

  const renderFilters = () => (
    <div className="mb-6">
      <h3 className="font-semibold text-gray-700 mb-2">Filters</h3>
      <div className="space-y-2">
        <div className="mb-4">
          <label htmlFor="product-type" className="block text-sm font-medium text-gray-700">Product Type</label>
          <select
            id="product-type"
            value={selectedProductType}
            onChange={(e) => setSelectedProductType(e.target.value)}
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
          >
            {productTypes.map(type => (
              <option key={type} value={type}>{type}</option>
            ))}
          </select>
        </div>
        <FilterDropdown
          title="Skin Issues"
          options={availableIssues}
          selectedOptions={selectedIssues}
          setSelectedOptions={setSelectedIssues}
        />
        <FilterDropdown
          title="Key Ingredients"
          options={availableIngredients}
          selectedOptions={selectedIngredients}
          setSelectedOptions={setSelectedIngredients}
        />
      </div>
    </div>
  );

  const renderProductCard = (product) => {
    const getIngredients = (product) => {
      if (product.baseline_info && product.baseline_info.ingredients) {
        if (Array.isArray(product.baseline_info.ingredients)) {
          return product.baseline_info.ingredients.slice(0, 3).join(', ');
        } else if (typeof product.baseline_info.ingredients === 'string') {
          return product.baseline_info.ingredients.split(', ').slice(0, 3).join(', ');
        }
      }
      return 'Ingredients information not available';
    };

    return (
      <motion.div
        key={product.id}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
        className="bg-white rounded-xl overflow-hidden shadow-sm hover:shadow-md transition-shadow duration-300 border border-gray-100"
      >
        <div 
          className="flex items-center p-4 cursor-pointer"
          onClick={() => handleProductClick(product)}
        >
          <img
            src={product.product_image_link}
            alt={product.product_name}
            className="w-16 h-16 object-cover rounded-lg"
          />
          <div className="ml-4 flex-1">
            <h3 className="font-semibold text-gray-800">{product.product_name}</h3>
            <p className="text-sm text-gray-600">{product.brand}</p>
            <div className="flex items-center mt-1">
              <Star className="text-yellow-400 mr-1" size={14} />
              <span className="text-sm text-gray-600">
                {product.product_rating} ({product.review_count})
              </span>
            </div>
          </div>
          <div className="text-right">
            <p className="font-bold text-primary">
              {product.product_price} {product.price_currency}
            </p>
            {selectedProduct === product.id ? (
              <ChevronUp size={16} className="text-gray-400 mt-1" />
            ) : (
              <ChevronDown size={16} className="text-gray-400 mt-1" />
            )}
          </div>
        </div>
        <AnimatePresence>
          {selectedProduct === product.id && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
              className="px-4 pb-4"
            >
              <div className="bg-gray-50 rounded-lg p-3 mt-2 text-sm">
                <p className="text-gray-700 leading-relaxed mb-2">{product.product_description}</p>
                <p className="font-semibold text-gray-800 mt-2">Key Ingredients:</p>
                <p className="text-gray-600">{getIngredients(product)}</p>
                <p className="font-semibold text-gray-800 mt-2">Professional Insight:</p>
                <p className="text-gray-600">{product.professional_description}</p>
                <div className="mt-4 flex flex-wrap gap-2">
                  <button 
                    onClick={() => handleSaveProduct(product.id)}
                    className={`flex items-center px-3 py-1 rounded-full text-sm transition-colors ${
                      isProductSaved(product.id) 
                        ? 'bg-primary text-white' 
                        : 'bg-white text-primary border border-primary hover:bg-primary hover:text-white'
                    }`}
                  >
                    <Heart size={14} className="mr-1" fill={isProductSaved(product.id) ? 'currentColor' : 'none'} />
                    {isProductSaved(product.id) ? 'Saved' : 'Save'}
                  </button>
                  <button
                    onClick={() => handleCompareToggle(product)}
                    className={`flex items-center px-3 py-1 rounded-full text-sm transition-colors ${
                      compareProducts.some(p => p.id === product.id)
                        ? 'bg-secondary text-primary'
                        : 'bg-white text-primary border border-primary hover:bg-secondary'
                    }`}
                  >
                    {compareProducts.some(p => p.id === product.id) ? 'Remove Compare' : 'Add to Compare'}
                  </button>
                  <a
                    href={product.product_link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center px-3 py-1 bg-primary text-white rounded-full hover:bg-primary-dark transition-colors text-sm"
                    onClick={() => handleExternalLinkClick(product)}
                  >
                    View Details
                    <ExternalLink size={14} className="ml-1" />
                  </a>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    );
  };

  const renderCompareView = () => (
    <div className="bg-white rounded-xl shadow-sm p-4 mb-6 border border-gray-100">
      <h3 className="font-semibold text-gray-800 mb-4">Compare Products</h3>
      <div className="grid grid-cols-3 gap-4">
        {compareProducts.map(product => (
          <div key={product.id} className="text-center">
            <img src={product.product_image_link} alt={product.product_name} className="w-full h-24 object-cover rounded-lg mb-2" />
            <p className="font-semibold text-sm">{product.product_name}</p>
            <p className="text-xs text-gray-600">{product.brand}</p>
            <p className="text-sm font-bold text-primary mt-1">{product.product_price} {product.price_currency}</p>
            <p className="text-xs text-gray-600 mt-1">Rating: {product.product_rating}</p>
            <button 
              onClick={() => handleCompareToggle(product)}
              className="mt-2 text-xs bg-red-50 text-red-600 px-2 py-1 rounded-full hover:bg-red-100 transition-colors"
            >
              Remove
            </button>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="h-full flex flex-col bg-gray-50">
      <div className="sticky top-0 z-10 bg-white text-gray-800 px-6 py-4 flex justify-between items-center shadow-sm border-b border-gray-200">
        <div className="flex items-center">
          <ShoppingBag className="mr-3 text-primary" size={24} />
          <h2 className="text-xl font-semibold">Recommended for You</h2>
        </div>
        <button 
          onClick={onClose}
          className="p-1 rounded-full hover:bg-gray-100 transition-colors focus:outline-none focus:ring-2 focus:ring-primary"
          aria-label="Close sidebar"
        >
          <X size={24} />
        </button>
      </div>
      <div className="flex-1 overflow-y-auto">
        <div className="p-6">
          {renderFilters()}
          {compareProducts.length > 0 && renderCompareView()}
          <div className="space-y-4">
            {filteredProducts.map(product => renderProductCard(product))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductSidebar;
