//src/pages/AccountPage/AccountPage.js

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import MySkin from './MySkin';
import FavoriteProducts from './FavoriteProducts';
import Settings from './Settings';
import {
  User,
  FileText,
  Heart,
  Settings as SettingsIcon,
  LogOut,
  AlertCircle,
  MapPin,
  Calendar,
  Menu,
  X,
} from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { getUserData } from '../../services/api';

const AccountPage = () => {
  const [userData, setUserData] = useState(null);
  const [activeSection, setActiveSection] = useState('mySkin');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.activeSection) {
      setActiveSection(location.state.activeSection);
    }
  }, [location]);

  useEffect(() => {
    const fetchUserDataWrapper = async () => {
      try {
        setLoading(true);
        const data = await getUserData();
        setUserData(data.user);
      } catch (err) {
        console.error('Error fetching user data:', err);
        setError('Failed to load user data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchUserDataWrapper();
  }, []);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Failed to log out', error);
      setError('Failed to log out. Please try again.');
    }
  };

  const renderContent = () => {
    switch (activeSection) {
      case 'mySkin':
        return <MySkin userData={userData} />;
      case 'favoriteProducts':
        return <FavoriteProducts userData={userData} />;
      case 'settings':
        return <Settings userData={userData} onUpdate={setUserData} />;
      default:
        return null;
    }
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gradient-to-r from-blue-100 via-purple-100 to-pink-100">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col justify-center items-center h-screen bg-gradient-to-r from-blue-100 via-purple-100 to-pink-100">
        <AlertCircle size={64} className="text-red-500 mb-4" />
        <h2 className="text-2xl font-bold text-gray-800 mb-2">Oops! Something went wrong.</h2>
        <p className="text-gray-600 mb-4">{error}</p>
        <button 
          onClick={() => window.location.reload()} 
          className="bg-primary text-white px-4 py-2 rounded-full hover:bg-primary-dark transition duration-300"
        >
          Try Again
        </button>
      </div>
    );
  }

  if (!userData) {
    navigate('/login');
    return null;
  }

  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-100 via-purple-100 to-pink-100">
      {/* Mobile Header */}
      <div className="md:hidden bg-white shadow-md p-4 flex justify-between items-center">
        <img 
          src={userData.picture || `https://api.dicebear.com/6.x/initials/svg?seed=${userData.displayName}`}
          alt={userData.displayName} 
          className="w-10 h-10 rounded-full"
        />
        <button onClick={toggleMobileMenu} className="text-primary">
          {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
        </button>
      </div>

      <div className="flex flex-col md:flex-row">
        {/* Sidebar for desktop / Mobile menu */}
        <AnimatePresence>
          {(isMobileMenuOpen || window.innerWidth >= 768) && (
            <motion.nav 
              initial={{ x: -250 }}
              animate={{ x: 0 }}
              exit={{ x: -250 }}
              className="w-full md:w-64 bg-white shadow-lg md:h-screen"
            >
              <div className="p-5 border-b border-gray-200">
                <img 
                  src={userData.picture || `https://api.dicebear.com/6.x/initials/svg?seed=${userData.displayName}`}
                  alt={userData.displayName} 
                  className="w-20 h-20 rounded-full mx-auto mb-3"
                />
                <h2 className="text-xl font-semibold text-center">{userData.displayName}</h2>
                <p className="text-sm text-gray-500 text-center">{userData.email}</p>
                <div className="mt-2 flex items-center justify-center text-sm text-gray-500">
                  <MapPin size={14} className="mr-1" />
                  <span>{userData.location || 'Location not set'}</span>
                </div>
                <div className="mt-1 flex items-center justify-center text-sm text-gray-500">
                  <Calendar size={14} className="mr-1" />
                  <span>{userData.age ? `${userData.age} years old` : 'Age not set'}</span>
                </div>
              </div>
              <ul className="py-4">
                {[
                  { name: 'My Skin', icon: FileText, key: 'mySkin' },
                  { name: 'Favorite Products', icon: Heart, key: 'favoriteProducts' },
                  { name: 'Settings', icon: SettingsIcon, key: 'settings' },
                ].map((item) => (
                  <li key={item.key}>
                    <button
                      onClick={() => {
                        setActiveSection(item.key);
                        setIsMobileMenuOpen(false);
                      }}
                      className={`flex items-center w-full px-5 py-3 transition-colors duration-200 ${
                        activeSection === item.key ? 'bg-primary text-white' : 'text-gray-600 hover:bg-blue-50'
                      }`}
                    >
                      <item.icon size={20} className="mr-3" />
                      {item.name}
                    </button>
                  </li>
                ))}
              </ul>
              <div className="mt-auto p-5">
                <button
                  onClick={handleLogout}
                  className="flex items-center w-full px-4 py-2 text-sm text-red-600 transition-colors duration-200 rounded-full hover:bg-red-50"
                >
                  <LogOut size={18} className="mr-2" />
                  Logout
                </button>
              </div>
            </motion.nav>
          )}
        </AnimatePresence>

        {/* Main content */}
        <main className="flex-1 p-4 md:p-8 overflow-y-auto">
          <AnimatePresence mode="wait">
            <motion.div
              key={activeSection}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              {renderContent()}
            </motion.div>
          </AnimatePresence>
        </main>
      </div>
    </div>
  );
};

export default AccountPage;