// src/pages/AccountPage/Settings.js

import React, { useState, useEffect } from 'react';
import { Save, Bell, Mail, User, MapPin, Calendar } from 'lucide-react';
import { motion } from 'framer-motion';
import { updateUserSettings } from '../../services/api';
import { Country } from 'country-state-city';

const Settings = ({ userData, onUpdate }) => {
  const [displayName, setDisplayName] = useState(userData.displayName || '');
  const [age, setAge] = useState(userData.age || '');
  const [country, setCountry] = useState(userData.location || '');
  const [newsletter, setNewsletter] = useState(userData.preferences?.newsletter || false);
  const [notifications, setNotifications] = useState(userData.preferences?.notifications || false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const [countries, setCountries] = useState([]);

  useEffect(() => {
    setCountries(Country.getAllCountries());
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setSuccess(false);

    if (!displayName.trim()) {
      setError('Display name is required.');
      setIsLoading(false);
      return;
    }

    try {
      const updatedUserData = await updateUserSettings({
        displayName: displayName.trim(),
        age: age ? parseInt(age, 10) : null,
        location: country,
        preferences: { newsletter, notifications },
      });
      onUpdate(updatedUserData.user);
      setSuccess(true);
    } catch (error) {
      console.error('Error updating settings:', error);
      setError('Failed to update settings. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="max-w-2xl mx-auto"
    >
      <h2 className="text-3xl font-bold text-primary mb-8">Account Settings</h2>
      <form onSubmit={handleSubmit} className="space-y-8">
        <motion.div 
          className="bg-white rounded-lg shadow-md p-6"
          whileHover={{ boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)" }}
        >
          <h3 className="text-xl font-semibold mb-4 flex items-center">
            <User size={24} className="mr-2 text-primary" />
            Personal Information
          </h3>
          <div className="space-y-4">
            <div>
              <label htmlFor="displayName" className="block text-sm font-medium text-gray-700 mb-1">
                Display Name *
              </label>
              <input
                type="text"
                id="displayName"
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                required
                placeholder="Enter your display name"
              />
            </div>
            <div>
              <label htmlFor="age" className="block text-sm font-medium text-gray-700 mb-1">
                Age
              </label>
              <input
                type="number"
                id="age"
                value={age}
                onChange={(e) => setAge(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                placeholder="Enter your age"
                min="1"
                max="120"
              />
            </div>
            <div>
              <label htmlFor="country" className="block text-sm font-medium text-gray-700 mb-1">
                Country
              </label>
              <select
                id="country"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
              >
                <option value="">Select a country</option>
                {countries.map((country) => (
                  <option key={country.isoCode} value={country.isoCode}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </motion.div>

        <motion.div 
          className="bg-white rounded-lg shadow-md p-6"
          whileHover={{ boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)" }}
        >
          <h3 className="text-xl font-semibold mb-4">Preferences</h3>
          <div className="space-y-4">
            <label className="flex items-center space-x-3 cursor-pointer">
              <input
                type="checkbox"
                checked={newsletter}
                onChange={(e) => setNewsletter(e.target.checked)}
                className="form-checkbox h-5 w-5 text-primary"
              />
              <span className="text-gray-700">Subscribe to newsletter</span>
              <Mail size={20} className="text-gray-400" />
            </label>
            <label className="flex items-center space-x-3 cursor-pointer">
              <input
                type="checkbox"
                checked={notifications}
                onChange={(e) => setNotifications(e.target.checked)}
                className="form-checkbox h-5 w-5 text-primary"
              />
              <span className="text-gray-700">Enable notifications</span>
              <Bell size={20} className="text-gray-400" />
            </label>
          </div>
        </motion.div>

        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
            <span className="block sm:inline">{error}</span>
          </div>
        )}

        {success && (
          <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
            <span className="block sm:inline">Settings updated successfully!</span>
          </div>
        )}

        <motion.button
          type="submit"
          className="w-full bg-primary text-white py-2 px-4 rounded-full hover:bg-primary-dark transition duration-300 flex items-center justify-center"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          disabled={isLoading}
        >
          {isLoading ? (
            <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white mr-2"></div>
          ) : (
            <Save size={20} className="mr-2" />
          )}
          {isLoading ? 'Saving...' : 'Save Changes'}
        </motion.button>
      </form>
    </motion.div>
  );
};

export default Settings;