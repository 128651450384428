//src/pages/HomePage/EnhanceSkinCareJourney.js

import React from 'react';
import { motion } from 'framer-motion';
import { ClipboardList, Heart, Mail, CheckCircle, ArrowRight, MessageSquare } from 'lucide-react';

const EnhanceSkinCareJourney = ({ tasks, completedTasks, isChatAvailable, onStartChat }) => {
  return (
    <section className="py-16 bg-white bg-opacity-80">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8">Enhance Your Skincare Journey</h2>
        <div className="max-w-4xl mx-auto">
          <div className="mb-8 bg-blue-100 rounded-full h-4 overflow-hidden">
            <motion.div 
              className="bg-primary h-full rounded-full"
              initial={{ width: 0 }}
              animate={{ width: `${(completedTasks / tasks.length) * 100}%` }}
              transition={{ duration: 0.5, ease: "easeInOut" }}
            />
          </div>
          <p className="text-center mb-12 text-lg">
            You've completed {completedTasks} out of {tasks.length} tasks. Keep going to enhance your skincare experience!
          </p>
          
          {/* AI Chat Feature */}
          <motion.div 
            className={`bg-gradient-to-r from-blue-50 to-purple-50 rounded-lg shadow-lg p-6 mb-8 border ${
              isChatAvailable ? 'border-primary' : 'border-gray-200'
            }`}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <MessageSquare className="w-12 h-12 mr-4 text-primary" />
                <div>
                  <h3 className="text-2xl font-semibold text-gray-800">Chat with AI Assistant</h3>
                  <p className="mt-1 text-gray-600">Get instant answers to your skincare questions</p>
                </div>
              </div>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={onStartChat}
                disabled={!isChatAvailable}
                className={`flex items-center px-6 py-3 rounded-full transition duration-300 ${
                  isChatAvailable
                    ? 'bg-primary hover:bg-primary-dark text-white'
                    : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                }`}
              >
                {isChatAvailable ? "Start Chat" : "Complete Profile First"}
                <ArrowRight className="ml-2 w-5 h-5" />
              </motion.button>
            </div>
            {!isChatAvailable && (
              <p className="mt-4 text-sm bg-yellow-100 text-yellow-800 p-2 rounded">
                Complete your skin profile to unlock AI-powered personalized advice!
              </p>
            )}
          </motion.div>

          {/* Tasks */}
          <div className="grid gap-8">
            {tasks.map((task, index) => (
              <motion.div 
                key={index}
                className={`bg-white rounded-lg shadow-lg p-6 ${
                  task.completed ? 'border-2 border-green-500' : 'border border-gray-200'
                }`}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: index * 0.1 }}
              >
                <div className="flex items-center justify-between mb-4">
                  <div className="flex items-center">
                    <task.icon className={`w-10 h-10 mr-4 ${task.completed ? 'text-green-500' : 'text-primary'}`} />
                    <div>
                      <h3 className="text-xl font-semibold">{task.name}</h3>
                      <p className="text-gray-600 mt-1">{task.description}</p>
                    </div>
                  </div>
                  {task.completed ? (
                    <CheckCircle className="w-8 h-8 text-green-500" />
                  ) : (
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={task.action}
                      className="bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded-full transition duration-300"
                    >
                      Start
                      <ArrowRight className="ml-2 w-4 h-4 inline" />
                    </motion.button>
                  )}
                </div>
                <div className="mt-4">
                  <h4 className="font-semibold text-gray-700 mb-2">Benefits:</h4>
                  <ul className="list-disc list-inside text-gray-600">
                    {task.benefits.map((benefit, idx) => (
                      <li key={idx}>{benefit}</li>
                    ))}
                  </ul>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default EnhanceSkinCareJourney;