// src/components/Chatbot/ChatWindow.js
import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Send, Trash2, Bot, User, HelpCircle, Info } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import InfoTooltip from './InfoTooltip';
import QuickFeedback from '../feedbacks/QuickFeedback';

function ChatWindow({ 
  messages, 
  isLoading, 
  onSendMessage, 
  onClearChat, 
  productsAvailable, 
  isMobile,
  onShowIntro,
  onShowQuestionnaire,
  isSearchingProducts // Receive as prop
}) {
  const [inputMessage, setInputMessage] = useState('');
  const chatEndRef = useRef(null);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages, isLoading, isSearchingProducts]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputMessage.trim() && !isLoading) {
      onSendMessage(inputMessage.trim());
      setInputMessage('');
    }
  };

  const formatMessage = (message) => {
    const { content, isComplete } = message;
    let formattedContent = content;

    // If the message is not complete, render it as is
    if (!isComplete) {
      return (
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>{formattedContent}</ReactMarkdown>
      );
    }

    // Handle {QUESTIONNAIRE_LINK} placeholder
    if (formattedContent.includes('{QUESTIONNAIRE_LINK}')) {
      const parts = formattedContent.split('{QUESTIONNAIRE_LINK}');
      return (
        <>
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>{parts[0]}</ReactMarkdown>
          <button
            onClick={onShowQuestionnaire}
            className="bg-primary text-white px-4 py-2 rounded-full mt-2 hover:bg-primary-dark transition-colors"
          >
            Take the Skin Assessment
          </button>
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>{parts[1]}</ReactMarkdown>
        </>
      );
    }

    // Define custom renderers for ReactMarkdown
    const components = {
      // Custom renderer for <TOOLTIP> tags
      tooltip: ({node, ...props}) => (
        <InfoTooltip tooltipText={props.children} />
      ),
      // You can add other custom renderers here if needed
    };

    return (
      <ReactMarkdown
        rehypePlugins={[rehypeRaw]}
        components={components}
      >
        {formattedContent}
      </ReactMarkdown>
    );
  };

  return (
    <div className="flex flex-col h-full bg-white shadow-lg overflow-hidden rounded-b-lg relative">
      {/* Help Button */}
      <div className="absolute top-2 left-2 z-20">
        <button
          onClick={onShowIntro}
          className="p-2 text-gray-600 hover:text-primary transition-colors focus:outline-none"
          aria-label="Help"
        >
          <HelpCircle size={24} />
        </button>
      </div>

      {/* Messages */}
      <div className={`flex-1 overflow-y-auto ${isMobile ? 'pb-20' : ''}`}>
        <div className="p-5 space-y-4"> {/* Changed from p-4 to p-5 */}
          {messages.map((message, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'}`}
            >
              <div
                className={`flex items-start space-x-2 max-w-[85%] ${
                  message.role === 'user' ? 'flex-row-reverse space-x-reverse' : ''
                }`}
              >
                <div
                  className={`flex-shrink-0 w-8 h-8 rounded-full flex items-center justify-center ${
                    message.role === 'user' ? 'bg-primary text-white' : 'bg-gray-200 text-primary'
                  }`}
                >
                  {message.role === 'user' ? <User size={16} /> : <Bot size={16} />}
                </div>
                <div
                  className={`px-3 py-2 rounded-lg shadow ${
                    message.role === 'user' ? 'bg-primary text-white' : 'bg-gray-100'
                  }`}
                >
                  {message.role === 'user' ? (
                    <p className="text-sm">{message.content}</p>
                  ) : (
                    <div className="prose prose-sm max-w-none">
                      {formatMessage(message)}
                      {/* Display QuickFeedback after assistant message if complete */}
                      {!isLoading && message.isComplete && (
                        <QuickFeedback message={message} />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </motion.div>
          ))}
          {/* Loading Indicator for Assistant Typing */}
          {isLoading && (
            <div className="flex justify-start">
              <div className="bg-gray-100 text-primary px-3 py-2 rounded-lg shadow">
                <div className="typing-indicator flex space-x-1">
                  <span className="w-2 h-2 bg-gray-400 rounded-full animate-bounce"></span>
                  <span className="w-2 h-2 bg-gray-400 rounded-full animate-bounce delay-200"></span>
                  <span className="w-2 h-2 bg-gray-400 rounded-full animate-bounce delay-400"></span>
                </div>
              </div>
            </div>
          )}
          {/* Loading Indicator for Searching Products */}
          {isSearchingProducts && (
            <div className="flex justify-start">
              <div className="bg-gray-100 text-primary px-3 py-2 rounded-lg shadow mt-2">
                <div className="flex items-center space-x-2">
                  <div className="typing-indicator flex space-x-1">
                    <span className="w-2 h-2 bg-gray-400 rounded-full animate-bounce"></span>
                    <span className="w-2 h-2 bg-gray-400 rounded-full animate-bounce delay-200"></span>
                    <span className="w-2 h-2 bg-gray-400 rounded-full animate-bounce delay-400"></span>
                  </div>
                  <p className="text-sm text-gray-500">Searching for products...</p>
                </div>
              </div>
            </div>
          )}
          <div ref={chatEndRef} />
        </div>
      </div>

      {/* Input */}
      <div
        className={`border-t border-gray-200 p-2 md:p-4 ${
          isMobile ? 'fixed bottom-0 left-0 right-0 bg-white' : ''
        }`}
      >
        <form onSubmit={handleSubmit} className="flex items-center space-x-2">
          <button
            type="button"
            onClick={onClearChat}
            className="p-2 text-gray-500 hover:text-primary transition-colors"
            aria-label="Clear Chat"
          >
            <Trash2 size={20} />
          </button>
          <input
            type="text"
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            className="flex-1 px-3 py-2 text-base md:text-base rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent "
            placeholder="Type your message..."
            disabled={isLoading}
            aria-label="Chat Input"
          />
          <button
            type="submit"
            className={`p-2 bg-primary text-white rounded-full hover:bg-primary-dark transition-colors ${
              isLoading || !inputMessage.trim() ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            disabled={isLoading || !inputMessage.trim()}
            aria-label="Send Message"
          >
            <Send size={20} />
          </button>
        </form>
      </div>
    </div>
  );
}

// Define a separate component for the Info Icon to keep things clean
const InfoIcon = () => (
  <Info size={16} className="inline-block" />
);

export default ChatWindow;
