// src/components/OnboardingModal.js

import React, { useState, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Country } from 'country-state-city';
import QuestionnaireModal from '../../components/QuestionnaireModal'; // Adjusted import path
import CustomSelect from '../../components/CustomSelect'; // Adjusted import path
import Tooltip from '../../components/Tooltip'; // Adjusted import path
import { Info, User } from 'lucide-react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const OnboardingModal = ({ onComplete, isOpen, onClose }) => {
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({
    displayName: '',
    age: '',
    location: null,
    questionnaire: {},
    preferences: {
      newsletter: false,
      notifications: false,
    },
  });
  const navigate = useNavigate();

  const countryOptions = useMemo(
    () =>
      Country.getAllCountries().map(country => ({
        value: country.isoCode,
        label: country.name,
      })),
    []
  );

  if (!isOpen) return null;

  const handleChange = e => {
    const { name, value, type, checked } = e.target;
    if (name.startsWith('preferences.')) {
      const preference = name.split('.')[1];
      setFormData(prevData => ({
        ...prevData,
        preferences: { ...prevData.preferences, [preference]: checked },
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: type === 'checkbox' ? checked : value,
      }));
    }
  };

  const handleCountryChange = selectedOption => {
    setFormData(prevData => ({
      ...prevData,
      location: selectedOption,
    }));
  };

  const handleNext = () => setStep(prevStep => prevStep + 1);
  const handleBack = () => setStep(prevStep => prevStep - 1);

  const handleQuestionnaireComplete = questionnaireData => {
    setFormData(prevData => ({
      ...prevData,
      questionnaire: questionnaireData,
    }));
    handleNext();
  };

  /**
   * Handle final submission by passing data and navigation path to parent
   * @param {string} path - The path to navigate to after submission
   */
  const handleFinalSubmit = (path) => {
    const submissionData = {
      ...formData,
      age: formData.age ? parseInt(formData.age, 10) : null,
      location: formData.location ? formData.location.label : '',
      onboardingCompleted: true,
    };
    onComplete(submissionData, path);
  };

  const isCurrentStepValid = () => {
    // Welcome screen doesn't require validation
    if (step === 0) return true;
    switch (step) {
      case 1:
        return formData.displayName.trim() !== '' && formData.location !== null;
      case 2:
        return Object.keys(formData.questionnaire).length > 0;
      case 3:
        return true;
      default:
        return true;
    }
  };

  const steps = [
    // Step 0: Welcome Screen
    <motion.div
      key="welcomeScreen"
      initial={{ opacity: 0, x: -50 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 50 }}
      className="space-y-6 text-center"
    >
      {/* Replace the below with an image or icon representing welcome */}
      {/* Example: <img src={welcomeImage} alt="Welcome" className="mx-auto w-1/2 h-auto" /> */}
      <div className="flex justify-center">
        <User className="w-24 h-24 text-primary" /> {/* Lucide-react User Icon */}
      </div>
      <h2 className="text-3xl font-bold text-primary">Welcome to SkinMingle!</h2>
      <p className="text-gray-600 mt-4">
        Your journey to personalized skincare starts here.
      </p>
      <button
        onClick={handleNext}
        className="btn btn-primary w-full sm:w-auto mt-6"
      >
        Get Started
      </button>
    </motion.div>,

    // Step 1: Basic Info
    <motion.div
      key="basicInfo"
      initial={{ opacity: 0, x: -50 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 50 }}
      className="space-y-6"
    >
      <div className="flex items-center justify-between">
        <div>
          <div className="flex items-center">
            <div className="flex items-center justify-center w-8 h-8 bg-primary text-white rounded-full">
              1
            </div>
            <h2 className="ml-4 text-2xl font-bold text-primary">Tell Us About Yourself</h2>
          </div>
          <p className="text-gray-600 mt-2">This will help us personalize your skincare journey.</p>
        </div>
        <User className="w-24 h-24 text-primary" /> {/* Lucide-react User Icon */}
      </div>
      <div>
        <label className="block text-gray-700 font-medium mb-2 flex items-center">
          Display Name *
          <Tooltip content="This name will be visible to other users.">
            <Info className="ml-2 text-gray-400 w-4 h-4 cursor-pointer" />
          </Tooltip>
        </label>
        <input
          type="text"
          name="displayName"
          value={formData.displayName}
          onChange={handleChange}
          placeholder="Enter your display name"
          className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary text-base sm:text-lg"
        />
      </div>
      <div>
        <label className="block text-gray-700 font-medium mb-2">Age (Optional)</label>
        <input
          type="number"
          name="age"
          value={formData.age}
          onChange={handleChange}
          placeholder="Enter your age"
          min="1"
          max="120"
          className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary text-base sm:text-lg"
        />
      </div>
      <div>
        <label className="block text-gray-700 font-medium mb-2">Country *</label>
        <CustomSelect
          options={countryOptions}
          value={formData.location}
          onChange={handleCountryChange}
          placeholder="Select your country"
        />
      </div>
      <div className="flex justify-end">
        <button
          onClick={handleNext}
          disabled={!isCurrentStepValid()}
          className={`btn ${isCurrentStepValid() ? 'btn-primary' : 'bg-gray-300 text-gray-500 cursor-not-allowed'} w-full sm:w-auto`}
        >
          Next
        </button>
      </div>
    </motion.div>,

    // Step 2: Questionnaire
    <QuestionnaireModal
      key="questionnaire"
      initialData={formData.questionnaire}
      onComplete={handleQuestionnaireComplete}
      isOnboarding={true}
    />,

    // Step 3: Preferences
    <motion.div
      key="preferences"
      initial={{ opacity: 0, x: 50 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -50 }}
      className="space-y-6"
    >
      <div className="flex items-center">
        <div className="flex items-center justify-center w-8 h-8 bg-primary text-white rounded-full">
          3
        </div>
        <h2 className="ml-4 text-2xl font-bold text-primary">Your Preferences</h2>
      </div>
      <p className="text-gray-700">Customize your experience:</p>
      <label className="flex items-center mt-4">
        <input
          type="checkbox"
          name="preferences.newsletter"
          checked={formData.preferences.newsletter}
          onChange={handleChange}
          className="form-checkbox text-primary h-5 w-5"
        />
        <span className="ml-3 text-gray-700 text-base sm:text-lg">
          Subscribe to our newsletter for skincare tips and product updates
        </span>
      </label>
      <label className="flex items-center mt-4">
        <input
          type="checkbox"
          name="preferences.notifications"
          checked={formData.preferences.notifications}
          onChange={handleChange}
          className="form-checkbox text-primary h-5 w-5"
        />
        <span className="ml-3 text-gray-700 text-base sm:text-lg">
          Enable notifications for personalized skincare reminders
        </span>
      </label>
      <div className="flex justify-between mt-6 flex-col sm:flex-row">
        <button
          onClick={handleBack}
          className="btn bg-gray-300 text-gray-800 hover:bg-gray-400 w-full sm:w-auto mb-4 sm:mb-0"
        >
          Back
        </button>
        <button
          onClick={handleNext}
          className={`btn btn-primary w-full sm:w-auto`}
        >
          Next
        </button>
      </div>
    </motion.div>,

    // Step 4: Final Step - Encourage Exploration
    <motion.div
      key="finalStep"
      initial={{ opacity: 0, x: 50 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -50 }}
      className="space-y-6 text-center"
    >
      {/* Replace the below with an image or icon representing success/completion */}
      {/* Example: <img src={completionImage} alt="Completed" className="mx-auto w-1/2 h-auto" /> */}
      <div className="flex justify-center">
        <svg className="w-24 h-24 text-primary" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          {/* SVG path of a checkmark icon */}
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
        </svg>
      </div>
      <h2 className="text-2xl font-bold text-primary">You're All Set!</h2>
      <p className="text-gray-600 mt-4">
        Start exploring SkinMingle's features now.
      </p>
      <div className="flex flex-col sm:flex-row items-center justify-center space-y-4 sm:space-y-0 sm:space-x-4 mt-6">
        <button
          onClick={() => handleFinalSubmit('/home')}
          className="btn btn-primary w-full sm:w-auto"
        >
          Go to Home
        </button>
        <button
          onClick={() => handleFinalSubmit('/chatbot')}
          className="btn btn-secondary w-full sm:w-auto"
        >
          Go to Chatbot
        </button>
      </div>
    </motion.div>,
  ];

  // Adjust progressPercentage calculation
  const progressPercentage = step === 0 ? 0 : ((step) / (steps.length - 1)) * 100;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white p-6 sm:p-8 rounded-lg max-w-lg w-full max-h-screen overflow-y-auto relative shadow-lg">
        {/* Progress Bar */}
        <div className="mb-6">
          <div className="w-full bg-gray-200 rounded-full h-2">
            <div
              className="bg-primary h-2 rounded-full"
              style={{ width: `${progressPercentage}%` }}
            ></div>
          </div>
          <p className="text-sm text-gray-500 mt-2 text-center">
            Step {step + 1} of {steps.length}
          </p>
        </div>
        {/* Step Content */}
        <AnimatePresence mode="wait">
          {steps[step]}
        </AnimatePresence>
        {/* Privacy Policy and Help Links */}
        <div className="text-center mt-4">
          <p className="text-sm text-gray-500">
            By continuing, you agree to our{' '}
            <a 
              href="https://app.termly.io/policy-viewer/policy.html?policyUUID=f00f5ece-cf77-4ca4-9b65-6aabac3bc7c0" 
              className="text-primary underline" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            .
          </p>
          <button
            onClick={() => { /* Implement show FAQ modal logic here */ }}
            className="text-sm text-primary mt-2 underline"
          >
            Need Help?
          </button>
        </div>
      </div>
    </div>
  );
};

export default OnboardingModal;
