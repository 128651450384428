// src/utils/decodeProductId.js

export const decodeProductId = (encodedId) => {
  try {
    return atob(encodedId);
  } catch (error) {
    console.error('Failed to decode product ID:', error);
    return null;
  }
};
