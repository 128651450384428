// src/pages/LoginPage/LoginPage.js

import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { auth } from '../../firebase';
import * as firebaseui from 'firebaseui';
import firebase from '../../firebase'; // Ensure correct path
import 'firebaseui/dist/firebaseui.css';
import OnboardingModal from './OnboardingModal';
import { useAuth } from '../../contexts/AuthContext'; // Import useAuth

function LoginPage() {
  const navigate = useNavigate();
  const { currentUser, userData, error: authError, setUserData } = useAuth(); // Destructure setUserData
  const [error, setError] = useState('');
  const [isOffline, setIsOffline] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [loading, setLoading] = useState(true); // Start with loading as true

  // Define a ref for FirebaseUI instance to prevent multiple initializations
  const uiRefInstance = useRef(null);

  /**
   * Monitor online/offline status
   */
  useEffect(() => {
    const checkOnlineStatus = () => {
      const offline = !navigator.onLine;
      setIsOffline(offline);
      console.log(`User is ${offline ? 'offline' : 'online'}.`);
    };

    window.addEventListener('online', checkOnlineStatus);
    window.addEventListener('offline', checkOnlineStatus);

    // Initial check
    checkOnlineStatus();

    return () => {
      window.removeEventListener('online', checkOnlineStatus);
      window.removeEventListener('offline', checkOnlineStatus);
    };
  }, []);

  /**
   * Initialize FirebaseUI using a callback ref
   */
  const initializeFirebaseUI = useCallback(
    (element) => {
      if (element && !uiRefInstance.current && !isOffline) {
        console.log('Initializing FirebaseUI...');
        // Initialize the FirebaseUI Widget using Firebase.
        const ui =
          firebaseui.auth.AuthUI.getInstance() ||
          new firebaseui.auth.AuthUI(auth);

        const uiConfig = {
          signInFlow: 'popup',
          signInOptions: [
            {
              provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
              customParameters: {
                prompt: 'select_account',
              },
            },
            {
              provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
              requireDisplayName: false,
            },
          ],
          callbacks: {
            signInSuccessWithAuthResult: () => {
              // Prevent FirebaseUI from handling redirects
              console.log('User signed in successfully.');
              return false;
            },
            uiShown: () => {
              console.log('FirebaseUI Widget is shown.');
            },
          },
          tosUrl:
            'https://app.termly.io/policy-viewer/policy.html?policyUUID=725bee98-20cd-4227-bded-30cad73cd33f',
          privacyPolicyUrl:
            'https://app.termly.io/policy-viewer/policy.html?policyUUID=f00f5ece-cf77-4ca4-9b65-6aabac3bc7c0',
        };

        try {
          ui.start(element, uiConfig);
          uiRefInstance.current = ui; // Store the instance to prevent re-initialization
          console.log('FirebaseUI initialized successfully.');
        } catch (initError) {
          console.error('Error initializing FirebaseUI:', initError);
        }
      }
    },
    [isOffline]
  );

  /**
   * Handle authentication errors from AuthContext
   */
  useEffect(() => {
    if (authError) {
      setError(authError);
      setLoading(false);
    }
  }, [authError]);

  /**
   * Handle navigation and onboarding based on user data
   */
  useEffect(() => {
    if (currentUser && userData) {
      setLoading(false);
      console.log('User data fetched:', userData);
      if (!userData.onboardingCompleted) {
        setShowOnboarding(true);
        console.log('User has not completed onboarding. Showing modal.');
      } else {
        console.log('User has completed onboarding. Navigating to home.');
        navigate('/home');
      }
    } else if (currentUser && !userData) {
      // User is logged in but userData is being fetched or created
      setLoading(true);
      console.log('User is logged in, fetching or creating userData.');
    } else {
      // No user is logged in
      setLoading(false);
      console.log('No user is logged in.');
    }
  }, [currentUser, userData, navigate]);


  /**
   * Handle completion of onboarding
   * @param {Object} onboardingData - Data collected during onboarding
   */
  const handleOnboardingComplete = async (onboardingData) => {
    try {
      setLoading(true);
      console.log('Submitting onboarding data:', onboardingData);
      const token = await currentUser.getIdToken();
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(onboardingData),
      });
  
      if (!response.ok) {
        throw new Error('Failed to complete onboarding');
      }
  
      const data = await response.json();
      console.log('Onboarding completed successfully:', data);
      // Update userData in context with the complete data from the server
      setUserData(data.user);
      setShowOnboarding(false);
      navigate('/home');
    } catch (error) {
      console.error('Error during onboarding:', error);
      setError('Failed to complete onboarding. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  

  /**
   * Render loading spinner
   */
  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gradient-to-r from-blue-100 via-purple-100 to-pink-100">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
  }

  /**
   * Main render
   */
  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-100 via-purple-100 to-pink-100 flex items-center justify-center px-4">
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="max-w-md w-full bg-white rounded-xl shadow-2xl overflow-hidden"
      >
        <div className="bg-gradient-to-r from-primary to-primary-dark p-6 text-white">
          <h2 className="text-3xl font-bold text-center mb-2">Welcome to Skinmingle</h2>
          <p className="text-center text-sm opacity-80">
            Log in or sign up to start your personalized skincare journey
          </p>
        </div>
        <div className="p-8">
          {error && (
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="text-red-500 text-center mb-4"
            >
              {error}
            </motion.p>
          )}
          {isOffline ? (
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="text-center text-gray-600 mb-6"
            >
              You are currently offline. Please check your internet connection and try again.
            </motion.p>
          ) : (
            <>
              <div id="firebaseui-auth-container" ref={initializeFirebaseUI}></div>
            </>
          )}
        </div>
      </motion.div>

      <AnimatePresence>
        {showOnboarding && (
          <OnboardingModal
            isOpen={showOnboarding}
            onComplete={handleOnboardingComplete}
          />
        )}
      </AnimatePresence>

      {/* Include the LocationModal if needed */}
      {/* <LocationModal isOpen={showLocationModal} onClose={() => setShowLocationModal(false)} /> */}
    </div>
  );
}

export default LoginPage;
