import React from 'react';
import { motion } from 'framer-motion';
import { Cpu, Database, Zap, Shield, Smartphone, BarChart } from 'lucide-react';

const OurTechnology = () => {
  const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 }
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-100 via-purple-100 to-pink-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <motion.h1 
          className="text-4xl font-bold text-center text-gray-800 mb-8"
          {...fadeIn}
        >
          Our Technology
        </motion.h1>

        <motion.section className="bg-white rounded-xl shadow-lg p-8 mb-8" {...fadeIn}>
          <h2 className="text-2xl font-semibold mb-4 text-primary">The Power of AI in Skincare</h2>
          <p className="text-gray-600 mb-4">
            At Skinmingle, we harness the power of artificial intelligence to revolutionize 
            your skincare routine. Our advanced AI algorithms analyze various factors such as 
            skin type, concerns, lifestyle, and environment to provide you with personalized 
            skincare recommendations.
          </p>
          <p className="text-gray-600">
            By combining machine learning with dermatological expertise, we've created a 
            platform that learns and evolves, continually improving its ability to provide 
            tailored skincare advice.
          </p>
        </motion.section>

        <motion.section className="grid md:grid-cols-2 gap-8 mb-8" {...fadeIn}>
          <div className="bg-white rounded-xl shadow-lg p-6">
            <Cpu className="w-12 h-12 text-primary mb-4" />
            <h3 className="text-xl font-semibold mb-2">Advanced AI Algorithms</h3>
            <p className="text-gray-600">
              Our proprietary AI algorithms process thousands of data points to generate 
              personalized skincare recommendations with high accuracy.
            </p>
          </div>
          <div className="bg-white rounded-xl shadow-lg p-6">
            <Database className="w-12 h-12 text-primary mb-4" />
            <h3 className="text-xl font-semibold mb-2">Comprehensive Database</h3>
            <p className="text-gray-600">
              We maintain an extensive, up-to-date database of skincare products, ingredients, 
              and their effects on different skin types and conditions.
            </p>
          </div>
          <div className="bg-white rounded-xl shadow-lg p-6">
            <Zap className="w-12 h-12 text-primary mb-4" />
            <h3 className="text-xl font-semibold mb-2">Real-time Analysis</h3>
            <p className="text-gray-600">
              Our system provides instant analysis and recommendations, allowing you to make 
              informed skincare decisions on the go.
            </p>
          </div>
          <div className="bg-white rounded-xl shadow-lg p-6">
            <Shield className="w-12 h-12 text-primary mb-4" />
            <h3 className="text-xl font-semibold mb-2">Data Security</h3>
            <p className="text-gray-600">
              We employ state-of-the-art encryption and security measures to ensure your 
              personal data and skin information are always protected.
            </p>
          </div>
        </motion.section>

        <motion.section className="bg-white rounded-xl shadow-lg p-8 mb-8" {...fadeIn}>
          <h2 className="text-2xl font-semibold mb-4 text-primary">How It Works</h2>
          <ol className="list-decimal list-inside space-y-4 text-gray-600">
            <li>Complete our comprehensive skin assessment questionnaire.</li>
            <li>Our AI analyzes your responses and compares them with our vast database.</li>
            <li>Receive a personalized skincare routine and product recommendations.</li>
            <li>Track your progress and receive updated recommendations as your skin changes.</li>
          </ol>
        </motion.section>

        <motion.section className="bg-white rounded-xl shadow-lg p-8" {...fadeIn}>
          <h2 className="text-2xl font-semibold mb-4 text-primary">Continuous Innovation</h2>
          <div className="flex items-center mb-4">
            <Smartphone className="w-8 h-8 text-primary mr-4" />
            <p className="text-gray-600">
              Our mobile app allows for easy tracking and updating of your skincare routine.
            </p>
          </div>
          <div className="flex items-center">
            <BarChart className="w-8 h-8 text-primary mr-4" />
            <p className="text-gray-600">
              Advanced analytics provide insights into your skin's progress over time.
            </p>
          </div>
        </motion.section>
      </div>
    </div>
  );
};

export default OurTechnology;