// src/contexts/AuthContext.js

import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';

const AuthContext = createContext();

/**
 * Custom hook to access AuthContext
 */
export function useAuth() {
  return useContext(AuthContext);
}

/**
 * AuthProvider component to wrap around the application
 */
export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [token, setToken] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true); // Indicates if auth state is being checked
  const [error, setError] = useState('');

  /**
   * Logout function
   */
  function logout() {
    return signOut(auth).then(() => {
      setCurrentUser(null);
      setToken(null);
      setUserData(null);
    });
  }

  useEffect(() => {
    let refreshTokenInterval;

    /**
     * Function to fetch user data from backend
     * @param {string} token - Firebase Auth token
     */
    const fetchUserData = async (token) => {
      try {
        // Attempt to fetch existing user data
        let response = await fetch(`${process.env.REACT_APP_API_URL}/api/user`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.status === 404) {
          // User does not exist; create a new user profile
          const createResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/user`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          });

          if (createResponse.ok) {
            const createData = await createResponse.json();
            setUserData(createData.user);
          } else {
            throw new Error('Failed to create user profile.');
          }
        } else if (response.ok) {
          // User exists; set user data
          const data = await response.json();
          setUserData(data.user);
        } else if (response.status === 401) {
          // Unauthorized; possibly due to invalid/expired token
          const user = auth.currentUser;
          if (user) {
            const newToken = await user.getIdToken(true); // Force refresh token
            setToken(newToken);

            // Retry fetching user data with the new token
            response = await fetch(`${process.env.REACT_APP_API_URL}/api/user`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${newToken}`,
              },
            });

            if (response.ok) {
              const retryData = await response.json();
              setUserData(retryData.user);
            } else if (response.status === 404) {
              // If user still not found, create user
              const retryCreateResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/user`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${newToken}`,
                },
              });

              if (retryCreateResponse.ok) {
                const retryCreateData = await retryCreateResponse.json();
                setUserData(retryCreateData.user);
              } else {
                throw new Error('Failed to create user profile after token refresh.');
              }
            } else {
              throw new Error('Failed to fetch user profile after token refresh.');
            }
          } else {
            throw new Error('User is not authenticated.');
          }
        } else {
          throw new Error('Failed to fetch user profile.');
        }
      } catch (err) {
        console.error('Error fetching user data:', err);
        setError(err.message);
        setUserData(null);
      }
    };

    /**
     * onAuthStateChanged listener to monitor authentication state
     */
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser(user);
        try {
          const token = await user.getIdToken();
          setToken(token);
          await fetchUserData(token);

          // Set up proactive token refresh every 55 minutes
          refreshTokenInterval = setInterval(async () => {
            try {
              const newToken = await user.getIdToken(true);
              setToken(newToken);
              await fetchUserData(newToken);
            } catch (refreshError) {
              console.error('Error refreshing token:', refreshError);
              setError(refreshError.message);
            }
          }, 55 * 60 * 1000); // 55 minutes
        } catch (err) {
          console.error('Error during authentication state change:', err);
          setError(err.message);
          setUserData(null);
        }
      } else {
        // User is signed out
        setCurrentUser(null);
        setToken(null);
        setUserData(null);
      }
      setLoading(false); // Auth state has been checked
    });

    /**
     * Cleanup on unmount
     */
    return () => {
      unsubscribe();
      if (refreshTokenInterval) {
        clearInterval(refreshTokenInterval);
      }
    };
  }, []);

  /**
   * Context value to be provided to consumers
   */
  const value = {
    currentUser,
    token,
    userData,
    setUserData, // Expose setUserData
    logout,
    error,
  };

  /**
   * Render children only when not loading
   */
  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
}
