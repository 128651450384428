//src/pages/AccountPage/MySkin.js
import React, { useState, useEffect } from 'react';
import {
  FileText,
  Leaf,
  Clock,
  Frown,
  AlertTriangle,
  Droplet,
  Sun,
  Wind,
  Activity,
} from 'lucide-react';
import { motion } from 'framer-motion';
import QuestionnaireModal from '../../components/QuestionnaireModal';
import { updateQuestionnaire } from '../../services/api';

const ResultCard = ({ icon, title, value }) => (
  <motion.div
    className="bg-white rounded-lg shadow-md p-6 flex flex-col items-center"
    whileHover={{ y: -5, boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)" }}
    transition={{ duration: 0.2 }}
  >
    <div className="text-primary mb-4">{icon}</div>
    <h3 className="text-lg font-semibold mb-2">{title}</h3>
    <p className="text-gray-600 text-center">{value || 'Not specified'}</p>
  </motion.div>
);

const MySkin = ({ userData }) => {
  const [questionnaire, setQuestionnaire] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showQuestionnaireModal, setShowQuestionnaireModal] = useState(false);

  useEffect(() => {
    setQuestionnaire(userData.questionnaire || {});
    setLoading(false);
  }, [userData]);

  const handleUpdateQuestionnaire = async (updatedQuestionnaireData) => {
    try {
      await updateQuestionnaire(updatedQuestionnaireData);
      setQuestionnaire(updatedQuestionnaireData);
      setShowQuestionnaireModal(false);
    } catch (error) {
      console.error('Error updating questionnaire:', error);
      setError('Failed to update questionnaire. Please try again.');
    }
  };

  if (loading) {
    return <div className="text-center p-4">Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500 text-center p-4">{error}</div>;
  }

  return (
    <div className="max-w-4xl mx-auto">
      <h2 className="text-3xl font-bold text-primary mb-8">My Skin Profile</h2>
      
      {questionnaire && Object.keys(questionnaire).length > 0 ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <p className="text-gray-600 mb-6">
            Based on your questionnaire responses, here's a summary of your skin profile:
          </p>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
            <ResultCard icon={<FileText size={32} />} title="Skin Type" value={questionnaire.skinType} />
            <ResultCard icon={<Leaf size={32} />} title="Sensitivity" value={questionnaire.skinSensitivity} />
            <ResultCard icon={<Clock size={32} />} title="Current Routine" value={questionnaire.currentRoutine} />
          </div>
          
          <h3 className="text-2xl font-semibold text-primary mb-6">Skin Characteristics</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-12">
            <ResultCard icon={<Droplet size={24} />} title="After Cleansing" value={questionnaire.feelingAfterCleansing} />
            <ResultCard icon={<Activity size={24} />} title="Skin Balance" value={questionnaire.skinType === "Combination" ? "Combination (Mixed)" : questionnaire.skinType} />
          </div>

          <h3 className="text-2xl font-semibold text-primary mb-6">Primary Skin Concerns</h3>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-12">
            {questionnaire.skinConcerns && questionnaire.skinConcerns.map((concern, index) => (
              <motion.div
                key={index}
                className="bg-white rounded-lg p-4 flex flex-col items-center shadow-md"
                whileHover={{ scale: 1.05, boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)" }}
                transition={{ duration: 0.2 }}
              >
                <Frown size={24} className="text-primary mb-2" />
                <p className="text-center text-sm">{concern}</p>
              </motion.div>
            ))}
          </div>
          
          <motion.button
            className="bg-primary text-white py-2 px-6 rounded-full hover:bg-primary-dark transition duration-300"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => setShowQuestionnaireModal(true)}
          >
            Update Skin Profile
          </motion.button>
        </motion.div>
      ) : (
        <motion.div
          className="bg-white rounded-lg shadow-md p-8 text-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <AlertTriangle size={48} className="text-primary mx-auto mb-4" />
          <h3 className="text-2xl font-semibold mb-4">Complete Your Skin Profile</h3>
          <p className="text-gray-600 mb-6">
            To get personalized skincare recommendations and unlock the full potential of Skinmingle, 
            please take a few minutes to complete our skin profile questionnaire.
          </p>
          <motion.button
            className="bg-primary text-white py-2 px-6 rounded-full hover:bg-primary-dark transition duration-300"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => setShowQuestionnaireModal(true)}
          >
            Take the Questionnaire
          </motion.button>
        </motion.div>
      )}

      {showQuestionnaireModal && (
        <QuestionnaireModal
          initialData={questionnaire}
          onComplete={handleUpdateQuestionnaire}
          onClose={() => setShowQuestionnaireModal(false)}
        />
      )}
    </div>
  );
};

export default MySkin;