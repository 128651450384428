// src/services/api.js

import { withAuth } from '../utils/withAuth';
import { decodeProductId } from '../utils/decodeProductId'; // Import the decoding function

const API_URL = process.env.REACT_APP_API_URL;

/**
 * Fetch user data.
 * GET /api/user
 */
export const getUserData = withAuth(async (token) => {
  let response = await fetch(`${API_URL}/api/user`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (response.status === 404) {
    // User does not exist, create the user profile
    response = await fetch(`${API_URL}/api/user`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error('Failed to create user profile');
    }
    // After creating, fetch the user data again
    response = await fetch(`${API_URL}/api/user`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error('Failed to fetch user data after creation');
    }
  } else if (!response.ok) {
    throw new Error('Failed to fetch user data');
  }

  return response.json();
});
/**
 * Update user data.
 * PATCH /api/user
 */
export const updateUserData = withAuth(async (data, token) => {
  const response = await fetch(`${API_URL}/api/user`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    throw new Error('Failed to update user data');
  }
  const updatedData = await response.json(); // Parse the JSON response
  return updatedData; // Return the updated user data
});





/**
 * Get saved products (encoded IDs).
 * GET /api/user/saved-products
 */
export const getSavedProducts = withAuth(async (token) => {
  const response = await fetch(`${API_URL}/api/user/saved-products`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error('Failed to fetch saved products');
  }
  return response.json();
});

/**
 * Save a product.
 * POST /api/user/saved-products
 * Body: { product_id: string }
 */
export const saveProduct = withAuth(async (productId, token) => {
  const response = await fetch(`${API_URL}/api/user/saved-products`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ product_id: productId }), // Send raw ID
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error || 'Failed to save product');
  }
  return response.json();
});

/**
 * Remove a saved product.
 * DELETE /api/user/saved-products
 * Body: { product_id: string }
 */
export const removeSavedProduct = withAuth(async (productId, token) => {
  const response = await fetch(`${API_URL}/api/user/saved-products`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({ product_id: productId }), // Send raw ID
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error || 'Failed to remove product');
  }

  return response.json();
});

/**
 * Update questionnaire.
 * PATCH /api/user/questionnaire
 */
export const updateQuestionnaire = withAuth(async (questionnaireData, token) => {
  const response = await fetch(`${API_URL}/api/user/questionnaire`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({ questionnaire: questionnaireData }),
  });
  if (!response.ok) {
    throw new Error('Failed to update questionnaire');
  }
  return response.json();
});

/**
 * Update user settings.
 * PATCH /api/user/settings
 */
export const updateUserSettings = withAuth(async (settingsData, token) => {
  const response = await fetch(`${API_URL}/api/user/settings`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(settingsData),
  });
  if (!response.ok) {
    throw new Error('Failed to update settings');
  }
  return response.json();
});

/**
 * Get product details.
 * POST /api/user/products/details
 * Body: { product_ids: string[] }
 */
export const getProductDetails = withAuth(async (productIds, token) => {
  const response = await fetch(`${API_URL}/api/user/products/details`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({ product_ids: productIds }), // Send raw IDs
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error || 'Failed to fetch product details');
  }
  return response.json();
});

// Add other API functions as needed
