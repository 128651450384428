// src/components/Chatbot/InfoTooltip.js
import React, { useState, useEffect, useRef } from 'react';
import { Info } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const InfoTooltip = ({ tooltipText }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState('center'); // 'left', 'center', 'right'
  const [tooltipDirection, setTooltipDirection] = useState('up'); // 'up' or 'down'
  const tooltipRef = useRef(null);
  const buttonRef = useRef(null);

  // Function to handle clicks outside the tooltip
  const handleClickOutside = (event) => {
    if (
      tooltipRef.current &&
      !tooltipRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setIsTooltipVisible(false);
    }
  };

  useEffect(() => {
    if (isTooltipVisible) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('touchstart', handleClickOutside);
      updateTooltipPosition(); // Update position when tooltip becomes visible
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [isTooltipVisible]);

  // Detect if the device is touch-enabled
  const isTouchDevice = () => {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  };

  const toggleTooltip = () => {
    setIsTooltipVisible((prev) => !prev);
  };

  const showTooltip = () => {
    if (!isTouchDevice()) {
      setIsTooltipVisible(true);
    }
  };

  const hideTooltip = () => {
    if (!isTouchDevice()) {
      setIsTooltipVisible(false);
    }
  };

  // Handle keyboard events for accessibility
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      toggleTooltip();
    }
    if (event.key === 'Escape') {
      setIsTooltipVisible(false);
      buttonRef.current.focus();
    }
  };

  // Update tooltip position based on viewport
  const updateTooltipPosition = () => {
    if (!tooltipRef.current || !buttonRef.current) return;

    const tooltipRect = tooltipRef.current.getBoundingClientRect();
    const buttonRect = buttonRef.current.getBoundingClientRect();
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    // Horizontal positioning
    if (buttonRect.left < viewportWidth / 3) {
      setTooltipPosition('left');
    } else if (buttonRect.right > (viewportWidth / 3) * 2) {
      setTooltipPosition('right');
    } else {
      setTooltipPosition('center');
    }

    // Vertical positioning
    if (buttonRect.top < viewportHeight / 2) {
      setTooltipDirection('down'); // Display tooltip below the icon
    } else {
      setTooltipDirection('up'); // Display tooltip above the icon
    }
  };

  // Tooltip styles based on position
  const getTooltipStyle = () => {
    let style = {};

    // Vertical positioning
    if (tooltipDirection === 'up') {
      style.bottom = '100%';
      style.marginBottom = '8px';
    } else {
      style.top = '100%';
      style.marginTop = '8px';
    }

    // Horizontal positioning
    if (tooltipPosition === 'left') {
      style.left = '0';
      style.transform = 'translateX(0)';
    } else if (tooltipPosition === 'right') {
      style.right = '0';
      style.left = 'auto';
      style.transform = 'translateX(0)';
    } else {
      // Center
      style.left = '50%';
      style.transform = 'translateX(-50%)';
    }

    return style;
  };

  return (
    <span className="relative inline-flex items-center">
      <button
        ref={buttonRef}
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
        onClick={toggleTooltip}
        onKeyDown={handleKeyDown}
        className="text-gray-500 hover:text-gray-700 focus:outline-none ml-1"
        aria-label="More information"
        aria-haspopup="true"
        aria-expanded={isTooltipVisible}
      >
        <Info size={18} />
      </button>

      <AnimatePresence>
        {isTooltipVisible && (
          <motion.div
            ref={tooltipRef}
            initial={{ opacity: 0, y: tooltipDirection === 'up' ? 5 : -5 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: tooltipDirection === 'up' ? 5 : -5 }}
            transition={{ duration: 0.2 }}
            className="absolute z-10 bg-gray-800 text-white text-base px-4 py-3 rounded-lg shadow-lg w-64 max-w-xs"
            style={getTooltipStyle()}
          >
            {tooltipText}
          </motion.div>
        )}
      </AnimatePresence>
    </span>
  );
};

export default InfoTooltip;
