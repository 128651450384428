// src/components/Navigation.js

import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { motion } from 'framer-motion';
import { Menu, X } from 'lucide-react';
import { updateUserData } from '../../services/api'; // Import the update function

function Navigation() {
  const { currentUser, userData, logout, setUserData } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const navItems = [
    { to: '/home', label: 'Home' },
    { to: '/account', label: 'MySkin' },
    { to: '/chatbot', label: 'Chatbot' },
  ];

  const handleLogout = async () => {
    try {
      await logout();
      // Optionally, navigate to login or home page after logout
    } catch (error) {
      console.error('Failed to log out', error);
    }
  };

  return (
    <nav className={`sticky top-0 z-50 bg-white bg-opacity-50 backdrop-filter backdrop-blur-lg shadow-md transition-colors duration-300`}>
      {/* Navigation Content */}
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center py-4">
          <Link to="/" className="flex items-center">
            <img
              src="/images/logo/black_logo.png"
              alt="Skinmingle Logo"
              className="h-8 w-auto mr-2"
            />
          </Link>

          {/* Desktop Menu */}
          <div className="hidden md:flex items-center space-x-4">
            {currentUser &&
              navItems.map((item) => (
                <Link
                  key={item.to}
                  to={item.to}
                  className={`px-3 py-2 rounded-full transition duration-300 nav-${item.label.toLowerCase()}`} // Added nav-<label> class
                >
                  <span
                    className={`${
                      location.pathname === item.to
                        ? 'bg-primary text-white'
                        : 'text-gray-600 hover:bg-gray-100'
                    } px-3 py-1 rounded-full`}
                  >
                    {item.label}
                  </span>
                </Link>
              ))}
            {currentUser ? (
              <button
                onClick={handleLogout}
                className="px-3 py-2 rounded-full text-gray-600 hover:bg-gray-100 transition duration-300"
              >
                Logout
              </button>
            ) : (
              <Link
                to="/login"
                className="px-3 py-2 rounded-full bg-primary text-white hover:bg-primary-dark transition duration-300"
              >
                Login
              </Link>
            )}
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden relative">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="p-2 rounded-full hover:bg-gray-100 transition duration-300"
              aria-label="Menu"
            >
              {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>

        {/* Mobile Menu */}
        <motion.div
          className={`md:hidden ${isMenuOpen ? 'block' : 'hidden'}`}
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: isMenuOpen ? 1 : 0, y: isMenuOpen ? 0 : -20 }}
          transition={{ duration: 0.3 }}
        >
          {currentUser &&
            navItems.map((item) => (
              <Link
                key={item.to}
                to={item.to}
                className={`block px-3 py-2 rounded-md my-1 transition duration-300 nav-${item.label.toLowerCase()}`} // Added nav-<label> class
                onClick={() => setIsMenuOpen(false)}
              >
                <span
                  className={`${
                    location.pathname === item.to
                      ? 'bg-primary text-white'
                      : 'text-gray-600 hover:bg-gray-100'
                  } px-3 py-1 rounded-full`}
                >
                  {item.label}
                </span>
              </Link>
            ))}
          {currentUser ? (
            <button
              onClick={() => {
                handleLogout();
                setIsMenuOpen(false);
              }}
              className="block w-full text-left px-3 py-2 rounded-md my-1 text-gray-600 hover:bg-gray-100 transition duration-300"
            >
              Logout
            </button>
          ) : (
            <Link
              to="/login"
              className="block px-3 py-2 rounded-md my-1 bg-primary text-white hover:bg-primary-dark transition duration-300"
              onClick={() => setIsMenuOpen(false)}
            >
              Login
            </Link>
          )}
        </motion.div>
      </div>
    </nav>
  );
}

export default Navigation;
